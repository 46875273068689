import React from 'react';
import css from './MapButtons.module.css';
import { useIntl } from 'react-intl';
const MapButtons = ({ latitude, longitude, isListingPage }) => {
  const intl = useIntl();
  const handleGoogleMapsClick = e => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(googleMapsUrl, '_blank');
  };

  const handleWazeClick = e => {
    const wazeUrl = `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;
    window.open(wazeUrl, '_blank');
  };

  return (
    <div className={isListingPage ? css.lMapContainer : css.mapContainer}>
      <button onClick={handleGoogleMapsClick} className={css.button}>
        {intl.formatMessage({ id: 'MapButtons.MapButtons.googleMap' })}
      </button>
      <button onClick={handleWazeClick} className={css.button}>
        {intl.formatMessage({ id: 'MapButtons.MapButtons.waze' })}
      </button>
    </div>
  );
};

export default MapButtons;
