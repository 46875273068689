import React, { useState } from 'react';
import { FieldFileUpload, IconSpinner, Modal, ShowPdfs } from '../../components';
import css from './EditListingAvailabilityPlanForm.module.css';
import axios from 'axios';
import { VscFilePdf } from 'react-icons/vsc';
import { TiDeleteOutline } from 'react-icons/ti';
export const UploadInvoice = props => {
  const { listingId, currentListing } = props;
  const { attributes } = currentListing || {};
  const { metadata } = attributes || {};
  const { invoices } = metadata || {};
  const [showModal, setShowModal] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pdfs, setPdfs] = useState(invoices);
  const [deleting, setDeleting] = useState(false);
  const [deletingError, setDeletingError] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const uploadFile = async file => {
    setLoading(true);
    const formData = new FormData();
    formData.append('invoice', file);
    formData.append('listingId', listingId);
    try {
      const res = await axios.post('/upload-file', formData);
      const { data } = res.data;
      setPdfs(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setLoading(false);
    }
  };
  const deleteFile = async (file, i) => {
    try {
      setDeleteIndex(i);
      setDeleting(true);
      const formData = new FormData();
      formData.append('link', file);
      formData.append('listingId', listingId);
      await axios.post('/delete-file', formData);
      setPdfs(pdfs.filter((pdf, index) => index !== i));
      setDeleting(false);
      setDeleteIndex(null);
    } catch (e) {
      console.log(e);
      setDeletingError(e);
      setDeleting(false);
      setDeleteIndex(null);
    }
  };
  return (
    <div className={css.timezonePicker}>
      <h3>Invoices</h3>
      {deletingError ? (
        <div className={css.error}>
          <p>{deletingError?.message || 'Something went wrong please try again'}</p>
        </div>
      ) : null}
      {pdfs && pdfs.length > 0 ? (
        <div className={css.invoices}>
          {pdfs.map((invoice, i) => (
            <div className={css.invoiceContainer}>
              {deleting && deleteIndex === i ? (
                <div className={css.deleting}>
                  <IconSpinner className={css.pdfIconSpinner} />
                </div>
              ) : (
                <VscFilePdf
                  className={css.pdfIcon}
                  onClick={() => {
                    setInvoice(invoice);
                    setShowModal(true);
                    // if (typeof window !== 'undefined') window.open(invoice, '_blank');
                  }}
                />
              )}
              {!deleting ? (
                <TiDeleteOutline
                  className={css.deleteIcon}
                  onClick={async () => {
                    deleteFile(invoice, i);
                  }}
                />
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
      {error ? (
        <div className={css.error}>
          <p>{error?.message || 'Something went wrong please try again'}</p>
        </div>
      ) : null}

      <FieldFileUpload
        className={css.title}
        name="invoice"
        id="invoice"
        // acceptFiles="images"
        label={
          <div className={css.file}>
            <div className={css.uploadLabel}>{loading ? 'Uploading...' : 'Upload Invoice'}</div>
          </div>
        }
        center={false}
        showUploading={true}
        // uploading={loading}
        onSelectFile={async file => {
          await uploadFile(file);
        }}
      />
      <Modal
        id="invoice-modal"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onManageDisableScrolling={() => {}}
        containerClassName={css.modalContainer}
        className={css.modalContent}
        usePortal
      >
        <div className={css.modalHeader}>
          <h1 className={css.modalTitle}>Invoice</h1>
          <ShowPdfs link={invoice} />
        </div>
      </Modal>
    </div>
  );
};
