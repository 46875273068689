import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        These Master License Terms and Conditions (“Terms and Conditions”) apply to any License
        Agreement related to a license to access and use AutoMarketplace Platforms and associated
        Services (as such capitalized terms are defined herein below).
      </p>
      <p>
        Acceptance: Auto Marketplace, LLC (AutoMarketplace) owns and operates the website www.automarketplace.io
        (Site). AutoMarketplace owns and operates an online marketplace platform for automotive service (Marketplace).
        AutoMarketplace develops, operates and distributes certain mobile software applications (Apps).
        AutoMarketplace also develops, operates and distributes certain software as a service platform (SaaS). The
        Site, Marketplace, Apps and SaaS, together with the referral, matching, service quoting and appointment services,
        and the data collection, storage, analysis and reporting tools, functions and services, that are provided by the
        Site, Marketplace, Apps, and/or SaaS are collectively referred to as the Service.
      </p>
      <p>
        The Service is offered and provided subject to acceptance without modification of all of the
        terms and conditions contained herein (Terms of Service). The Terms of Service shall be
        deemed to include all other operating rules, conditions, policies and procedures that are
        referred to herein or that may otherwise be published by AutoMarketplace, from time to time,
        at the Site, Marketplace, via any App (collectively, Policies), or via any SaaS platform,
        including without limitation, the Privacy Policy and Copyright Policy.
      </p>
      <p>
        IF YOU DO NOT AGREE TO ALL OF THESE TERMS OF SERVICE, OR IF YOU ARE NOT ELIGIBLE OR
        AUTHORIZED TO ENTER INTO THIS AGREEMENT, THEN DO NOT REGISTER FOR, DOWNLOAD, ACCESS OR
        USE THE SERVICE. DOWNLOADING ANY APP, COMPLETING OUR REGISTRATION PROCESS OR OTHERWISE
        ACCESSING OR USING ALL OR ANY PART OF THE SERVICE WILL CONSTITUTE ACCEPTANCE OF, AND CREATE
        A LEGALLY ENFORCEABLE CONTRACT UNDER WHICH YOU AGREE TO BE BOUND BY, ALL OF THESE TERMS OF
        SERVICE, WITHOUT MODIFICATION.
      </p>
      <p>
        Updates: AutoMarketplace reserves the right, at its sole discretion, to update, modify
        or replace the Terms of Service (including any Policy), in whole or in part, at any time.
        AutoMarketplace will use reasonable efforts to notify you of any material change in advance
        of the effective date of any change. Change notices may be communicated by postings at the
        Site, on the Marketplace, via the Apps, via SaaS platform, e-mail or otherwise. In any case,
        you should periodically check the Policies and other Terms of Service for changes. Continued
        access or use of the Service following any change to the Terms of Service constitutes your
        acceptance of those changes. The Terms of Service may not otherwise be amended, as they apply
        to you, except by a written agreement executed by you and AutoMarketplace.
      </p>
      <p>
        AutoMarketplace may modify, suspend or terminate the Service (including without limitation,
        the Site, marketplace, any App, or SaaS), in whole or in part, at any time.
      </p>
      <p>
        Eligibility: The Service is intended by AutoMarketplace to be made available to individuals
        of all ages. However, if you are under the age of 18, you should review these terms of
        service with your parent or guardian to make sure that you or your parent or guardian
        understand the terms contained in these terms of service. The Service is intended by
        AutoMarketplace to be made available only to individuals who are at least 18 years old.
        If you do not qualify, then you are prohibited from downloading, accessing, using and
        registering for the Service. AutoMarkplace will not collect personally identifiable information
        from any person who is actually known to us to be under the age of 13. If we become aware that
        a person under 13 has provided personally identifiable information, AutoMarketplace will take
        steps to remove such information and terminate that individual’s account, access and use of the
        Service.
      </p>
      <p>
        AutoMarketplace may refuse to offer or continue offering the Service to any person and
        may change its eligibility criteria from time to time.
      </p>
      <p>
        Registration: The Service may be accessed several ways: by visiting the Site directly;
        or by using a web plug-in that is made available to you by AutoMarketplace; or by using
        an App that is downloaded from AutoMarketplace (or a Channel Partner), which then
        communicates with AutoMarketplace’s web servers; or by using a widget that is made
        available to you by a Channel Partner, which operates or otherwise connects to
        AutoMarketplace’s web servers (and which may operate within a frame or wrapper provided
        by the Channel Partner). The term Channel Partner means any Internet publisher, website
        operator, reseller, distributor or other third party who is authorized to drive user traffic,
        or facilitate access, to the Service pursuant to arrangements with AutoMarketplace.
      </p>
      <p>
        Regardless of how you access the Service, to ensure the integrity of the Service, you
        must complete an account registration process and provide AutoMarketplace with current,
        complete and accurate information, as more specifically required by then current
        registration procedures and operational prompts.
      </p>
      <p>
        By way of illustration and not limitation, you may be required to provide the
        following information in order to establish your account and register for and use
        the Service: name, address, telephone number, e-mail address, vehicle information
        (make, model, year, mileage and VIN), username, password and certain preferences
        and permissions (such as, for example, preferred contact method). You are required
        to maintain and update your registration data from time to time, to ensure that it is
        always current, complete and accurate.
      </p>
      <p>
        AutoMarketplace may refuse to accept your application to register for the Service,
        in its sole discretion. Upon acceptance, AutoMarketplace will establish access credentials
        for your account. You are solely responsible for maintaining the confidentiality of your
        access credentials and other account information, and you will be solely liable for any
        and all activities under your account. You agree to notify AutoMarketplace immediately of
        any unauthorized use of your account or any other breach of security related to the Service.
      </p>
      <p>
        Vehicle Services on AutoMarketplace: The Service includes the ability for users to
        investigate, compare service estimates, book appointments, purchase vehicle maintenance
        services, mechanical repair services, roadside assistance, car washes, tire services,
        vehicle transportation and related services (Vehicle Services) from certain dealerships,
        independent repair shops and independent contractors (Automotive Service Businesses, Towing
        Companies & Vehicle Transportation Companies). Based on your query, AutoMarketplace will
        solicit service estimates on your behalf from automotive service businesses, Towing Companies
        or Vehicle Transportation Companies that are willing to provide for your requested Vehicle
        Services. If you elect to accept any such an estimate for Vehicle Services and book an
        appointment with the applicable business, Towing Company or Vehicle Transportation Company
        then you will be required to provide valid Credit Card Information (cardholder name, card
        number, expiration date and security code), which will be verified by AutoMarketplace’s
        service provider (merchant bank) before your Vehicle Service is confirmed.
      </p>
      <p>
        For clarity, all Vehicle Services are provided by the applicable automotive service business,
        Towing Company or Vehicle Transportation Company, not AutoMarketplace. Accordingly, Vehicle
        Services are not deemed to be within the scope of the Service offered and provided by
        AutoMarketplace, and AutoMarketplace (and its Business Partners, other than the applicable
        automotive service business, Towing Company or Vehicle Transportation Company) have no
        obligation or liability regarding Vehicle Services.
      </p>
      <p>
        As a result of its limited role as an intermediary between you and the Vehicle Service
        providers, AutoMarketplace has no control over the quality, timeliness, price, guarantees,
        risks or damages related to Vehicle Services. Therefore, you agree, in connection with any
        use of the Service resulting in the purchase and performance of Vehicle Services: (a) to release
        AutoMarketplace (and its Business Partners, other than the applicable automotive service
        business, Towing Company or Vehicle Transportation Company from any and all claims, demands,
        damages and liabilities, of any type and nature, known and unknown, suspected and unsuspected,
        disclosed and undisclosed, arising out of or in any way connected with such Vehicle Services
        (including any disputes arising therefrom); and (b) to attempt to settle any dispute directly
        with the applicable Vehicle Service provider.
      </p>
      <p>
        Fees and Payments: Except as expressly provided otherwise in the Terms of Service,
        AutoMarketplace and each user is solely responsible for all of its costs and expenses
        related to the Service.
      </p>
      <p>
        At this time, as a vehicle owner you may access and use the Service without charge. In
        the future, AutoMarketplace may decide to charge for certain Apps or other features of
        the Service (or to change the price charged for such Apps and features), in which case you
        will be notified of that change. Should you elect to obtain any such fee-bearing App or
        feature, you hereby agree to pay all applicable fees, in the amounts, at the times and
        subject to any other conditions established for that App or feature. Such fees may be
        specified on a recurring basis (e.g., subscription or usage fees) or non-recurring basis
        (e.g., one-time fees).
      </p>
      <p>
        You are solely liable for the cost of Vehicle Services you receive. The price for
        Vehicle Services (including any applicable taxes) will be established by the applicable
        automotive service business, as set forth in the offer you accepted (or as increased or
        decreased as a result of changes in the scope of Vehicle Services to which you and the
        automotive service business have agreed). You hereby agree that AutoMarketplace may
        immediately charge the full and final price to your credit card after the automotive
        service business notifies AutoMarketplace that the Vehicle Services have been completed.
        After receipt of payment from your credit card, AutoMarketplace will retain a service
        fee and pay the remainder to the automotive service business, in accordance with the
        arrangement between them.
      </p>
      <p>
        Unless specified otherwise, all amounts due hereunder shall be paid in US dollars, 
        in immediately available funds by credit card, in full without set-off, counterclaim 
        or deduction. Any amount not paid when due shall bear a late payment charge until paid 
        at the rate of 1.5% per month or the maximum amount permitted by law, whichever is less. 
        If any payment is past due, AutoMarketplace shall have the right to take whatever action 
        it deems appropriate (including without limitation, suspending or terminating your access 
        and use of the Service). You agree to reimburse AutoMarketplace for all reasonable costs 
        and expenses (including attorneys’ fees) incurred in collecting payments.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
