import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import IconLogo from './IconLogo';
import LogoImage from '../../assets/logo032024.png';
import footerLogo from '../../assets/logo032024.png';
import logoText from '../../assets/logoText.png';
// import LogoImage from './color_logo.png';
// import footerLogo from './white_logo.png';
import css from './Logo.module.css';

const Logo = props => {
  const { className, format, variant, landingPage = null, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);
  const desktopClasses = classNames(css.logoDesktop, className);
  const footerClasses = classNames(css.logoFooter, className);

  if (format === 'desktop' && variant !== 'footer') {
    return (
      <div className={css.mainContainer}>
        <img className={desktopClasses} src={LogoImage} alt={config.siteTitle} {...rest} />
        {landingPage ? (
          <p className={css.logoText}>
            <img src={logoText} className={css.logoTextImage} alt="AutoMarketplace" />
          </p>
        ) : null}
      </div>
    );
  } else if (format === 'desktop' && variant === 'footer') {
    return <img className={footerClasses} src={footerLogo} alt={config.siteTitle} {...rest} />;
  }

  return <IconLogo className={mobileClasses} variant {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
