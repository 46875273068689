import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { BsFillUmbrellaFill } from 'react-icons/bs';
import { IoIosUmbrella } from 'react-icons/io';
import css from './AutomktRewards.module.css';
import captialAccessImg from './capitalAccess.png';
import cryptoRewardsImg from './cryptoReward.png';
import maintenanceImg from './maintenance.png';
import verticalLine from './verticalLine.png';
import commingSoon from './commingSoon.jpeg';
import { AiFillStar } from 'react-icons/ai';
import car from './carU.png'
import border from '../../assets/border.png';
const heading = () => {
  return (
    <StaticPage
      title="Services"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Services',
        description: 'Services',
        name: 'Services',
      }}
      description={'automarketplace.com'}
      isStaticPage={true}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>

            {/* <IoIosUmbrella className={css.umbrella} /> */}
            <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>Insurance Services</h1>
              <img src={border} className={css.border} alt="AutoMarketplace" />
            </div>
            <h2 className={css.subheading}>NYC FHV (TLC) Insurance</h2>
            <p className={css.paragraph}>Do you need help:</p>
            <ul className={css.list}>
              <li className={css.paragraphli}>
                Comparing NYC commercial for-hire vehicle insurance costs?
              </li>
              <li className={css.paragraphli}>Lowering your TLC insurance premium?</li>
              <li className={css.paragraphli}>
                Getting “full coverage” insurance for your vehicle?
              </li>
              <li className={css.paragraphli}>Managing an insurance claim or process?</li>
            </ul>
            <p className={css.email}>
              Email:{' '}
              <a className={css.achor} href="mailto:insurance@automarketplace.com">
                insurance@automarketplace.com
              </a>
            </p>
            <p className={css.emailOnMobile}>
              Contact:{' '}<br/>
              <a className={css.achor} href="mailto:insurance@automarketplace.com">
                insurance@automarketplace.com
              </a>
            </p>
          <img src={car} className={css.umbrella} alt="AutoMarketplace" />

            <p className={css.more}>More services coming soon...</p>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default heading;
