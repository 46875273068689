import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText, convertYoutubeLinksToIframes } from '../../util/richText';

import css from './ProfilePage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_BIO = 20;

const youtubeIframe = ytl => {
  const src = '//www.youtube.com/embed/' + ytl;
  return (
    <iframe
      className={css.youtubeIframe}
      src={src}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  );
};

const SectionBioMaybe = props => {
  const { bio } = props;
  const parsedBioLinks = convertYoutubeLinksToIframes(bio);
  return bio ? (
    <div className={css.sectionBio}>
      <h2 className={css.bioTitle}>{/* <FormattedMessage id="ProfilePage.about" /> */}</h2>
      <p className={css.bio}>
        {richText(parsedBioLinks[0], {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_BIO,
          longWordClass: css.longWord,
        })}

        {parsedBioLinks[1].map(l => youtubeIframe(l))}
      </p>
    </div>
  ) : null;
};

export default SectionBioMaybe;
