import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionWhyEthereum.module.css';
import ethereum from './images/ethereum.png';
import { string } from 'prop-types';

const SectionWhyEthereum = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.blueLine}></div>
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionWhyEthereum.title" />
      </h1>
      <div className={css.contentWrapper}>
        <div >
          <p className={css.paragraph}>
            <FormattedMessage id="SectionWhyEthereum.paragraph" />
          </p>
        </div>
        <img className={css.image} src={ethereum} alt="A man browsing on the Internet" />
      </div>
    </div>
  );
};

SectionWhyEthereum.defaultProps = { rootClassName: null, className: null };

SectionWhyEthereum.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWhyEthereum;
