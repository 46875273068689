import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionRespectingYourTime.module.css';
import time from './images/time.png';
import { string } from 'prop-types';

const SectionRespectingYourTime = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionRespectingYourTime.title" />
      </h1>

      <div>
        <p className={css.paragraph}>
          <FormattedMessage id="SectionRespectingYourTime.paragraph1" />
        </p>
      </div>
      {/* <img className={css.image} src={time} alt="Clock" /> */}
    </div>
  );
};

SectionRespectingYourTime.defaultProps = { rootClassName: null, className: null };

SectionRespectingYourTime.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionRespectingYourTime;
