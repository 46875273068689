import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionAboutUs.module.css';
import tyreChange from './images/tyreChange.png';
import { string } from 'prop-types';
import { FaArrowRight } from 'react-icons/fa';
import logoImage from '../../assets/logo032024.png';
import border from '../../assets/border.png';
import NamedLink from '../NamedLink/NamedLink';
import { AiFillStar } from 'react-icons/ai';
import { StaticPage } from '../../containers';
const SectionAboutUs = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'About Us',
        description: 'About Us',
        name: 'About Us',
      }}
      description={'automarketplace.com'}
      isStaticPage={true}
    >
      <div className={classes}>
        <div className={css.heroContainer}>
          <div className={css.contentWrapper}>
            <img src={logoImage} className={css.logo} alt="AutoMarketplace" />

            <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>About Us</h1>
              <img src={border} className={css.border} alt="AutoMarketplace" />
            </div>

            <div className={css.content}>
              <p className={css.paragraph}>
                Before starting AutoMarketplace, we operated a for-hire vehicle (“TLC”) fleet in New
                York City.
              </p>
              <p className={css.paragraph}>
                We know the frustrations of buying, financing, owning, maintaining & fixing vehicles
                in NYC. We also have an expertise in managing administrative work from TLC & DMV
                compliance to{' '}
                <NamedLink name="Service" className={css.link}>
                  insurance claims.
                </NamedLink>
              </p>
              <p className={css.paragraph}>AutoMarketplace is the site we needed for our fleet.</p>
            </div>
            {/* <br />
          <div className={css.youtube}>[YOUTUBE VIDEO - COMING SOON]</div>
          <br /> */}
            <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>NYC’s Best</h1>
              <img src={border} className={css.border} alt="AutoMarketplace" />
            </div>
            <p className={css.paragraph}>
              Google and Yelp ratings are helpful, but many shops and vendors manipulate their
              ratings.<b>Are there really that many places that are 4.0+?</b> Remember, many reviews
              are user generated and while useful, such a rating system can be abused. <br />
              <br />
              At AutoMarketplace we created our own rating (updated quarterly), based on private,
              verified user feedback and our own experience.
            </p>
            <div className={css.ratingSection}>
              <AiFillStar className={css.star} />

              <p className={css.ratingText}>
                3.5 = Good <br />
                4.0 = Very Good <br />
                4.5 = Excellent <br />
                4.6+ = Best in NYC
              </p>
            </div>
            <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>Trusted Brand In NYC TLC</h1>
              <img src={border} className={css.border} alt="AutoMarketplace" />
            </div>
            <p className={css.paragraph}>
              AutoMarketplace is the largest content producer and distribution platform focused on
              the NYC TLC market.
              <br />
              <br />
              Our content, including our widely read newsletter, is engaged by NYC drivers, fleets,
              insurance brokers, rideshare companies, bases, taxi medallion owners, driver
              advocates, policymakers and many others.
            </p>
            <div className={css.arrowContainer}>
              <p className={css.newsletter}>
                <a href="https://automarketplace.substack.com/" target="__blank">
                  Newsletter
                </a>
              </p>
              <p className={css.newsletter}>YouTube</p>
            </div>
            <br />
            <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>Contact Us</h1>
              <img src={border} className={css.border} alt="AutoMarketplace" />
            </div>
            <p className={css.emailMobile}><span className={css.email}>info@automarketplace.com</span> for any questions</p>
            <p className={css.emailDesktop}>If you have any questions, please email us at <span className={css.email}>info@automarketplace.com</span></p>
            <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>Video</h1>
              <img src={border} className={css.border} alt="AutoMarketplace" />
            </div>
            <div className={css.videoContainer}>
              <p className={css.paragraph} style={{ textAlign: 'center' }}>
                <i>
                  <b>coming soon...</b>
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </StaticPage>
  );
};

SectionAboutUs.defaultProps = { rootClassName: null, className: null };

SectionAboutUs.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAboutUs;
