import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import evImage from './ev.png';
import css from './ElectricVehicle.module.css';

const ElectricVehiclePage = () => {

  // prettier-ignore
  return (
    <StaticPage
      title="Electric Vehicles"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ElectricVehicle',
        description: 'Electric Vehicles',
        name: 'ElectricVehicle',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}><span className={css.span}><FormattedMessage id="ElectricVehiclesPage.titleWordE" /></span><FormattedMessage id="ElectricVehiclesPage.title" /></h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p className={css.paragraph}>
                <FormattedMessage id="ElectricVehiclesPage.paragraphOne" />
              </p>
              <p className={css.paragraph}>
                <FormattedMessage id="ElectricVehiclesPage.paragraphTwo" />
              </p>
              <img src={evImage} className={css.image} alt="EV image" />
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default ElectricVehiclePage;