import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
    {
        id: 'default-manhattan',
        predictionPlace: {
          address: 'Manhattan',
          bounds: new LatLngBounds(new LatLng(40.79045061, -73.93337655), new LatLng(40.70207936, -74.02829754)),
        },
      },
      {
        id: 'default-brooklyn',
        predictionPlace: {
          address: 'Brooklyn',
          bounds: new LatLngBounds(new LatLng(40.73835669, -73.85487872), new LatLng(40.56173127, -74.04432128)),
        },
      },
      {
        id: 'default-queens',
        predictionPlace: {
          address: 'Queens',
          bounds: new LatLngBounds(new LatLng(40.79986695, -73.72034031), new LatLng(40.62313999, -73.91006663)),
        },
      },
      {
        id: 'default-bronx',
        predictionPlace: {
          address: 'Bronx',
          bounds: new LatLngBounds(new LatLng(40.96158115, -73.75314879), new LatLng(40.78500076, -73.94318024)),
        },
      },
      {
        id: 'default-staten-island',
        predictionPlace: {
          address: 'Staten Island',
          bounds: new LatLngBounds(new LatLng(40.66544138, -74.05731452), new LatLng(40.48879951, -74.24656804)),
        },
      },

];
