import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import classNames from 'classnames';
import Typewriter from 'typewriter-effect';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import css from './SectionHero.module.css';
import SectionListings from './SectionListings';

const SectionHero = props => {
  const {
    rootClassName,
    className,
    handleOnClick,
    viewport,
    history,
    currentSearchParams,
    promotedListings,
    error,

    loading,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const handleSubmit = values => {
    const { query } = values;
    const { search, selectedPlace } = values.location || {};
    const { origin, bounds } = selectedPlace || {};
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const { LatLng, LatLngBounds } = sdkTypes;
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds:
        bounds ||
        new LatLngBounds(new LatLng(40.917577, -73.70001442), new LatLng(40.477399, -74.25909)),
      keywords: query,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.search}
      onSubmit={handleSubmit}
      desktopInputRoot={css.searchRoot}
      onSearchClick={handleSubmit}
      formId="hero-search-form"
    />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2672,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <div className={classes}>
      <div className={css.mainContainer}>
        <div className={css.heroContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.titleQuality" />
          </h1>
          <h1 className={css.heroCategoreyName}>
            <span className={css.typeWriter}>
              <Typewriter
                options={{
                  strings: [
                    'TLC Insurance',
                    'DMV Services',
                    'TLC Services',
                    'TLC Plate Rentals',
                    'TLC Car Rentals',
                    'Taxi Medallions',
                    'Taxi Garages',
                    'Black Car Bases',
                    // 'Auto Repair & Services',
                    // 'Tire & Rim Shops',
                    // 'Car Washes & Detail',
                    // 'Towing & Body Shops',
                    // 'Insurance',
                    // 'DMV Services',
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 60,
                  pauseFor: 2552,
                  deleteSpeed: 60,
                }}
              />
            </span>
          </h1>
          {search}
        </div>
        <div className={css.rightContainer}>
          <SectionListings promotedListings={promotedListings} error={error} loading={loading} />
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
