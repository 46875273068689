import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionWhatIsBlockchain.module.css';
import blockChain from './images/blockChain.png';
import { string } from 'prop-types';

const SectionWhatIsBlockchain = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.blueLine}></div>
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionWhatIsBlockchain.title" />
      </h1>
      <div className={css.contentWrapper}>
        <div >
          <p className={css.paragraph}>
            <FormattedMessage id="SectionWhatIsBlockchain.paragraph1" />
          </p>
          <p className={css.paragraph}>
            <FormattedMessage id="SectionWhatIsBlockchain.paragraph2" />
          </p>
          <p className={css.paragraph}>
            <FormattedMessage id="SectionWhatIsBlockchain.paragraph3" />
          </p>
          <p className={css.paragraph}>
            <FormattedMessage id="SectionWhatIsBlockchain.paragraph4" />
          </p>
        </div>
        <img className={css.image} src={blockChain} alt="Block Chain" />
      </div>
    </div>
  );
};

SectionWhatIsBlockchain.defaultProps = { rootClassName: null, className: null };

SectionWhatIsBlockchain.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWhatIsBlockchain;
