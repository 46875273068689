import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionWeb2Marketplace.module.css';
import pays200Receives176 from './images/pays200Receives176.svg';
import { string } from 'prop-types';

const SectionWeb2Marketplace = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionWeb2Marketplace.title1.1" />
      </h1>
      {/* <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionWeb2Marketplace.title1.2" />
      </h1> */}
      <div className={css.contentWrapper}>
        <div>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionWeb2Marketplace.paragraph1" /> */}
            Today’s online marketplaces primarily make money by connecting buyers & sellers of goods
            and services. In return for helping originate and facilitate transactions, these
            marketplaces take a broker commission, often referred to as a “take rate”.
            <b>
              {' '}
              This take rate typically ranges from 10% to 30% of a transaction’s value, helping
              maintain the financial viability of the marketplace
            </b>
            .
          </p>
          {/* <p className={css.paragraph}>
            <FormattedMessage id="SectionWeb2Marketplace.paragraph2" />
          </p> */}
        </div>
        <div className={css.imageContainer}>
          <img className={css.pays200Receives176} src={pays200Receives176} alt="buyer pays $200" />
        </div>
        <div>
          <p className={css.paragraph}>
            However, excessive commissions can also increase costs for both sides of a transaction.
            For example, a seller may increase a listing price to solve for a net price post
            commission. Initially, vendors and service providers may not mind the commissions, as it
            brings them new customers.
            <b className={css.colorRed}>
              {' '}
              However, as reputation is established (i.e. reviews), high take rates may cause
              frustration
            </b>
            .
          </p>
          {/* <p className={css.paragraph}>
            <FormattedMessage id="SectionWeb2Marketplace.paragraph3" />
          </p> */}
        </div>
      </div>
    </div>
  );
};

SectionWeb2Marketplace.defaultProps = { rootClassName: null, className: null };

SectionWeb2Marketplace.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWeb2Marketplace;
