import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionConfirmedPricing.module.css';
import shopping from './images/shopping.png';
import { string } from 'prop-types';
import NamedLink from '../NamedLink/NamedLink';
import { AiFillStar } from 'react-icons/ai';
const SectionConfirmedPricing = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      {/* <h1 className={css.sectionTitle}>
    
        <FormattedMessage id="SectionConfirmedPricing.title2" />
      </h1> */}
      <div className={css.contentWrapper}>
        <p className={css.paragraph}>
          We know the frustrations of buying, owning, maintaining, and fixing a vehicle in NYC.
          We’ve also dealt with various administration from DMV work to insurance claims.
          {/* Our team,
          which includes long time commercially licensed drivers, also knows the specific problems
          many NYC TLC-licensed drivers and fleets face. */}
        </p>
        {/* <p className={css.paragraph}>
            Finding trustworthy local auto repair & service providers, with transparent pricing, is
            difficult. According to
            <a
              href="https://newsroom.aaa.com/2016/12/u-s-drivers-leery-auto-repair-shops/"
              target="_blank"
            >
              <FormattedMessage id="SectionConfirmedPricing.paragraph1.2" />
            </a>
            <FormattedMessage id="SectionConfirmedPricing.paragraph1.3" />
          </p> */}

        {/* <h2 className={css.highlightRed}>
            <FormattedMessage id="SectionConfirmedPricing.headingTopReasons" />
          </h2> */}

        {/* <p className={css.paragraph}>
            😠 76% Unnecessary recommendations <br />
            💸 73% Overcharge <br />
            😒 63% Negative past experiences
          </p>
          <p className={css.paragraph}>
            Our content and directory provide pricing insight and reviews based on our{' '}
            <b>actual experience</b> and <b>real invoices</b>. We’ve visited every shop profiled in
            our directory. AutoMarketplace.com is a site we created for our vehicle fleet. We are
            our own customer.
          </p>
          <p className={css.paragraph}>
            If you have any questions, please email us at{' '}
            <a href="mailto:info@automarketplace.com">info@automarketplace.com</a>
          </p>
          <p className={css.paragraph}>
            You can also find us on
            <a href="https://www.youtube.com/channel/UCBLspAbS3IQu-w9QaEaVyQg" target="__blank">
              {' '}
              YouTube{' '}
            </a>
            and can ask us questions there too!
          </p> */}

        <p className={css.highLight}>
          AutoMarketplace is the site we needed when we ran our fleet. A trustworthy & transparent
          directory of local auto service shops & brokers.
          {/* A useful directory
            where our team rates dealers, auto services shops and brokers, based on real-life
            experiences, (including constantly updated pricing insights). */}
        </p>
        <h2 className={css.sectionTitle}>
          <AiFillStar className={css.star} />
          AM Rewards
        </h2>
        <p className={css.paragraph}>
          By creating a useful automotive directory, we’ll help drivers & fleets gain pricing power,
          while also sending customers to the most deserving dealers, shops & brokers. The
          <b> AutoMarketplace Rewards Program </b> is meant to provide discounts to those who use
          our directory and help share pricing insight.
        </p>
        <p className={css.paragraph}>
          Click <NamedLink name="AutomktRewards">here </NamedLink>
          to learn more about how the rewards program works..
        </p>
        <h2 className={css.sectionTitle}>💬 Reach Out</h2>
        <p className={css.paragraph}>
          If you have any questions, please email us at{' '}
          <a href="mailto:info@automarketplace.com">info@automarketplace.com</a> or call us at
          <a href="tel:(646) 685-4073"> (646) 685-4073</a>.
        </p>
        <p className={css.paragraph}>
          Find us on
          <a href="https://www.youtube.com/channel/UCBLspAbS3IQu-w9QaEaVyQg" target="_blank">
            {' '}
            YouTube{' '}
          </a>
          and subscribe to our{' '}
          <a href="https://automarketplace.substack.com/" target="_blank">
            Newsletter
          </a>
          , to follow our content.
        </p>
      </div>
    </div>
  );
};

SectionConfirmedPricing.defaultProps = { rootClassName: null, className: null };

SectionConfirmedPricing.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionConfirmedPricing;
