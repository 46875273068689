import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        The most current Privacy Policy will apply to any and all use of the Site, marketplace,
        Apps and other aspects of the Service. AutoMarketplace will not intentionally edit,
        modify, delete or disclose the contents of your registration data or other User Data
        in connection with the Service unless (a) reasonably necessary for AutoMarketplace
        (or its service providers) to operate the Service, including processing your requests
        and appointments for Vehicle Services, (b) to communicate with Business Partners, (c)
        otherwise permitted under the Privacy Policy or other Terms of Service, (d)
        AutoMarketplace reasonably believes that such action is necessary (i) to conform or
        comply with any legal, regulatory, law enforcement or similar requirement or
        investigation, (ii) to protect or defend the rights or property of AutoMarketplace
        or any third party or (iii) to enforce the Terms of Service or (e) otherwise authorized
        by you.
      </p>
      <p>
        User Data and Analytics: The Service collects, stores, processes and analyzes User
        Data and produces Analytics. Accordingly, whenever you interact with the Service, a
        variety of technologies are employed to automatically or passively collect User Data.
      </p>
      <p>
        The term User Data means all data and information that are provided by you during
        registration and subsequent use of the Service (including queries related to obtaining
        Vehicle Services), and all other data and information about you that are otherwise
        discerned or collected by AutoMarketplace based on your access and use of the Service.
        For clarity, User Data includes, but is not limited to: browser or device type;
        operating system; time of day; identification of the page served and preceding page views;
        use of particular Service features or applications (such as, for example, viewing or
        responding to advertisements); registration data; vehicle information; geographic location;
        Vehicle Service histories.
      </p>
      <p>
        The term Analytics means user profiles and statistics, metrics, abstractions and other
        analyses that are based on or derived from your use of the Service and User Data,
        which are developed in the aggregate with other data or which are developed in a
        manner that does not disclose your identity or User Data (unless in aggregated or
        anonymized form).
      </p>
      <p>
        You hereby authorize AutoMarketplace to use your User Data and to exploit Analytics
        in the manner and for the purposes described in these Terms of Service, including
        without limitation: to use User Data to communicate with you; to include, copy,
        disclose, distribute, transmit and display User Data in communications that you
        have initiated via the Service; to access, record, collect, copy, store, process,
        analyze and use User Data to provide the Service (including to solicit offers for
        Vehicle Services from Mechanics and to confirm appointments); to develop, improve,
        extend and test the Service (and underlying technology platform); to design, develop
        and produce Analytics; and to disclose, distribute and transmit User Data and/or
        Analytics to Channel Partners, Mechanics, Technicians, advertisers, advertising networks,
        content distributors and other of AutoMarketplace’s customers, service providers and
        business partners (collectively, Business Partners).
      </p>
      <p>
        Notwithstanding the foregoing, AutoMarketplace does not record or store your Credit
        Card Information. Credit Card Information is provided to and retained by AutoMarketplace’s
        merchant bank, where it is used solely for the purpose of verification and validation,
        and for charging payments for completed Vehicle Services.
      </p>
      <p>
        User Generated Content: The Service may include discussion forums or other
        communications features, through which users may publicly post and/or exchange
        content and information they think may be of interest to other users based on their
        use of the Service (such as, for example, reviews and ratings regarding Vehicle Services)
        and other related subjects (collectively, User Content). You agree not to post or
        distribute any confidential User Content or other information that you desire (or are
        required) to keep secret.
      </p>
      <p>
        AutoMarketplace does not undertake to pre-screen, authenticate, validate, monitor,
        moderate or edit any User Content. However, AutoMarketplace and its agents have the
        right, at their sole discretion, to remove in whole or in part, at any time, any User
        Content that, in AutoMarketplace’s judgment, does not comply with the Terms of Service
        or otherwise appears to be harmful, objectionable or inaccurate. AutoMarketplace is not
        responsible for any failure or delay in removing any such content.
      </p>
      <p>
        Because AutoMarketplace does not review the substance of User Content, or other
        user-to-user communications via the Service, you must be careful in dealing with
        other users to avoid unintended consequences. You acknowledge that information
        provided by users may, despite the prohibitions set forth in the Terms of Service,
        be harmful, inaccurate or deceptive. Authentication of the true identity of remote
        (Internet or mobile) users is difficult, and so AutoMarketplace cannot and does not
        confirm that each user is the person who they claim to be.
      </p>
      <p>
        AutoMarketplace is not involved in user-to-user dealings and, therefore, in
        connection with any use of the Service, you agree: (a) to release AutoMarketplace
        (and its affiliates, licensors, Channel Partners and other Business Partners) from
        any and all claims, demands, damages, liabilities, costs and expenses, of any type
        and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed,
        arising out of or in any way related to your use of the Service (including any disputes
        with other users); and (b) to attempt to settle any dispute directly with such other
        user or other third party. However, this release is not intended to waive any legal
        claim you may have against any Mechanic, Technician or Service Provider for defective
        Vehicles Services.
      </p>
      <p>
        User Rules and Conduct: The Site, Apps and all other aspects of the Service are provided
        only for your personal, noncommercial use. Any unauthorized use of the Service (including
        without limitation, accessing any aspect of the Service for which you are not authorized,
        or any commercial use by you, such as, for example, reselling any App, Content or Vehicle
        Services) is expressly prohibited. You are solely responsible for all acts or omissions
        that occur under your account, username or password, including applicable User Content
        posted to or transmitted via the Service.
      </p>
      <p>
        As a condition of use, you hereby promise not to use the Service for any purpose that
        is unlawful, or that is commercial in nature (such as, for example, raising money,
        or advertising or promoting any product, service, pyramid scheme or other venture)
        or that is prohibited by the Terms of Service, or for any other purpose not reasonably
        intended by AutoMarketplace. You agree to abide by all applicable local, state,
        national and international laws, regulations and rules.
      </p>
      <p>
        By way of example, and not limitation, you agree not to (a) take any action or (b)
        upload, post, submit or otherwise distribute or facilitate distribution of any User
        Content (including text, software, photographs, videos, audio, sound recordings, data
        or other information) using any communications service, discussion forum, survey,
        feedback, App or other aspect of the Service, in any manner, that:
        <ol className={css.unOrderedList}>
          <li>
            is unlawful, deceptive, misleading, fraudulent, threatening, abusive,
            harassing, libelous, invasive of another’s privacy, tortious, obscene,
            pornographic, profane or which otherwise violates the Terms of Service;
          </li>
          <li>
            infringes any patent, trademark, trade secret, copyright, right of publicity,
            privacy right or other right of any person or entity;
          </li>
          <li>
            reveals any confidential, proprietary or other information that you are required
            to keep secret, including without limitation, non-public information about any business;
          </li>
          <li>
            discovers or reveals any personal information about another individual, including
            another person’s name, address, phone number, e-mail address, credit card information
            or any other information that could be used to track, contact or impersonate that person,
            unless such information has been provided by that person in any public communication or forum;
          </li>
          <li>
            constitutes unauthorized or unsolicited advertising, junk or bulk e-mail (“spamming”),
            chain letters or any form of lottery or gambling;
          </li>
          <li>
            imposes an unreasonable or disproportionately large load on AutoMarketplace’s computing,
            storage or communications systems and networks, or attempts to gain unauthorized access
            to the Site, any App or other aspect of the Service (including the underlying technology
            platform), someone else’s User Data, accounts, computer systems or networks connected to
            the Service, through password mining or otherwise;
          </li>
          <li>
            contains software viruses or any other computer codes, files or programs that are
            designed or intended to disrupt, damage, limit or interfere with the proper function
            of any software, hardware or network system or to damage or obtain unauthorized access
            to any system, data or other information of AutoMarketplace or any Business Partner
            or other third party;
          </li>
          <li>
            registers for or creates user accounts by any automated means or under false or
            misleading pretenses, including by the use of a false, misleading or disguised
            e-mail address;
          </li>
          <li>
            harvests, scrapes or collects any information from the Service;
          </li>
          <li>
            uses any script, bot or other automated means that only simulates compliance
            with the Terms of Service or other requirement applicable to the Service;
          </li>
          <li>
            disguises the source of User Content; or
          </li>
          <li>
            impersonates any person or entity, including any employee or representative of
            AutoMarketplace or any Business Partner.
          </li>
        </ol>
      </p>
      <p>
        AutoMarketplace may, at its sole discretion, immediately suspend or terminate your
        access to the Service should your conduct fail (or appear to fail) to strictly conform
        to any provision of this section.
      </p>
      <p>
        AutoMarketplace has no obligation to monitor the Service or any user’s use thereof.
        However, AutoMarketplace reserves the right at any time and without notice to monitor,
        review, copy, retain or disclose any information as may reasonably be necessary to
        satisfy any applicable law, regulation, legal process or governmental request or
        investigation (including any inquiry from law enforcement).
      </p>
      <p>
        Connections: You may be able to obtain an App, access the Service and/or communicate
        with the Service from, and you may be able to link or communicate from the Service to,
        applications, devices and websites owned and operated by automotive service businesses,
        Channel Partners, advertisers or other Business Partners. These other applications, devices
        and websites are not operated or controlled by AutoMarketplace, and you agree that
        AutoMarketplace is not responsible for the availability, accuracy, legality, appropriateness
        or any other aspect of the content or function of such applications, devices and websites.
        Any such connection to or from the Service does not constitute or imply any guarantee or
        endorsement by AutoMarketplace of that application, device or website or any association with
        its operators. Additional or different terms and conditions (including without limitation,
        privacy and security practices) apply when you access and use such other applications, devices
        and websites.
      </p>
      <p>
        Advertising and Promotions: The Service may include advertisements and promotions for and
        offers from AutoMarketplace, Business Partners and other third parties. Advertisements may
        be displayed adjacent to or included with User Content, or may include User Content. You are not
        entitled to any compensation for any such advertisement or information.
      </p>
      <p>
        AutoMarketplace reserves the right, for itself and on behalf of its Business Partners, to
        deliver advertisements, promotions and offers to you in conjunction with your use of the
        Service and to utilize your device’s processor and storage resources for this purpose.
        Advertisements, promotions and offers may be contextual, meaning they were selected for
        delivery to you based on criteria that are related to your use of the Service (such as,
        for example, User Data like your current location or type of vehicle, or Analytics derived from
        your usage). Any opt-out that you provide regarding direct marketing communications will not
        apply to the delivery of advertisements. In addition, you may receive advertising and
        promotional information or offers as a result of responding to prompts or advertisements
        rendered by the Service.
      </p>
      <p>
        Except for Vehicle Services, any and all of your dealings with advertisers and other third
        parties via the Service, and your participation in promotions and offers, including the
        delivery of and the payment for goods and services, and any other terms, conditions,
        representations, warranties and remedies, associated with such dealings, promotions and offers,
        are solely between you and the applicable third party. AutoMarketplace is not responsible or
        liable for any such dealings, promotions or offers.
      </p>
      <p>
        Proprietary Rights: The Service (including the Site and Apps), and all content, materials,
        data and other information created by or for AutoMarketplace (or its affiliates, licensors
        or Business Partners) and made available via the Service (collectively, Content), are
        protected by copyrights, trademarks, service marks, patents, trade secrets or other proprietary
        rights and laws, and AutoMarketplace (and its applicable affiliates, licensors and Business Partners)
        shall own and retain all rights, title and interests (including all intellectual property and
        proprietary rights) therein and thereto. Unless and only to the extent expressly authorized by
        AutoMarketplace, you agree not to sell, license, rent, modify, distribute, copy, reproduce,
        transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works
        of any part of the Service or Content. However, unless the Service states or implements a
        different restriction, you may download, reproduce or transmit a reasonable number of copies of
        Content for your personal, noncommercial purposes; provided that you retain all copyright and
        other proprietary notices contained therein. Your systematic retrieval of data or other Content
        from the Service to create or compile, directly or indirectly, a collection, database or directory
        is strictly prohibited without AutoMarketplace’s express prior written permission.
      </p>
      <p>
        IMPORTANT: If you desire to access or use the Service or Content for any purpose other than personal,
        noncommercial reference, then you must first secure permission from AutoMarketplace or other owner
        of that Content.
      </p>
      <p>
        Reproducing, copying or distributing any App, or any Content, or any design elements from the Site
        or any App, for any other purpose is strictly prohibited without AutoMarketplace’s express prior
        written permission. Attempting to access or use the Service or Content for any purpose not expressly
        permitted in the Terms of Service is prohibited.
      </p>
      <p>
        License: Provided You comply with all terms and conditions of these Terms of Service, AutoMarketplace
        grants You a revocable, limited, non-transferable license to use the service on any iPhone or
        Android phone that You own or control and as permitted by Usage Rules set forth in the App Store
        Terms and Conditions or the Google Play Terms of Service, whichever is relevant.
      </p>
      <p>
        User Content License: If you upload, post, submit, transmit or otherwise distribute User Content
        of any kind using the Service, then you:
        <ol className={css.unOrderedList}>
          <li>
            agree to grant and do hereby grant to AutoMarketplace and its affiliates and licensees
            a non-exclusive, transferable, perpetual, irrevocable, royalty-free, worldwide right and
            license to: use, reproduce, publicly display, publicly perform, adapt, collect, modify,
            delete from, distribute, transmit, promote and make derivative works of the User Content,
            in any form and media, solely for the purposes of offering, providing, marketing, advertising
            and promoting the Site, Apps and other aspects of the Service (including without limitation,
            being exported under content sharing arrangements with Business Partners); and to permit third
            parties to use your User Content; and
          </li>
          <li>
            represent and warrant that all User Content includes appropriate attribution to the copyright
            owner; and
          </li>
          <li>
            represent and warrant to AutoMarketplace that you own or otherwise control all necessary rights
            to such User Content and that public disclosure, transmission and use of the User Content by
            AutoMarketplace (including without limitation, publishing content via the Service) will not
            infringe or violate the rights of any third party; and
          </li>
          <li>
            acknowledge and agree that User Content is intended to and will be made available to and
            used by other users, and by AutoMarketplace and its Business Partners.
          </li>
        </ol>
      </p>
      <p>
        IMPORTANT: You agree not to contribute any User Content unless you have all of the rights
        (including rights from the copyright owner) necessary to grant AutoMarketplace the foregoing
        use license, and to satisfy all of the warranty requirements described above.
      </p>
      <p>
        The Service may (but is not obligated to) provide you with the ability to remove some
        or all of your User Content. Following any such removal, AutoMarketplace will use commercially
        reasonable efforts to discontinue its further display, performance and distribution of such User
        Content; provided, however, you acknowledge and agree that AutoMarketplace may continue using
        such User Content in the same manner and for the same purposes as it did prior to removal of
        such content (such as, for example, as used in any advertising created prior to removal) and
        that AutoMarketplace shall have no responsibility or liability in respect of any further use
        of User Content that was previously exported by AutoMarketplace or shared with other users
        via the Service or that was downloaded or copied by other users to other websites, systems
        and devices.
      </p>
      <p>
        Cellular or Wireless Service: The Service transmits data via your wireless carrier or
        accessible Wi-Fi network. AutoMarketplace is not responsible or liable for any data
        charges associated with the use of any Service. The service may occasionally be restricted,
        interrupted or discontinued due to conditions such as, but not limited to: app design,
        network coverage, government regulation, or other situations beyond AutoMarketplace’s
        control. The Service transmits data regarding Your GPS location. You may turn off Location
        Services for the Service and no data will be collected during this time.
      </p>
      <p>
        Feedback License: If you provide AutoMarketplace with any idea, recommendation or other suggestion
        regarding possible corrections, improvements or extensions related to the Service (collectively,
        Feedback), in any form or by any means (whether through the Service, or by direct communication
        (e.g., e-mail) with AutoMarketplace or any of its officers, directors, employees, representatives,
        agents or Business Partners), then you:
        <ol className={css.unOrderedList}>
          <li>
            represent and warrant that the Feedback does not include your own or any third party’s
            confidential or proprietary information; and
          </li>
          <li>
            acknowledge and agree that AutoMarketplace is under no obligation of confidentiality, express
            or implied, with respect to the Feedback; and
          </li>
          <li>
            agree to grant and do hereby grant to AutoMarketplace and its affiliates and licensees a non-exclusive,
            transferable, perpetual, irrevocable, royalty-free, worldwide right and license to use, modify and make
            derivative works of the Feedback, in any manner, in any media and for any purpose, and to permit third
            parties to do the same.
          </li>
        </ol>
      </p>
      <p>
        Termination: AutoMarketplace may immediately terminate your access to all or any part of the Service
        (including without limitation, any App) at any time, with or without cause, with or without notice.
        If you elect to terminate your registration and account, you may do so at any time by sending an email
        (that includes your email address) to info@AutoMarketplace.io.
      </p>
      <p>
        Upon any termination, all rights and obligations of the parties under these Terms of Service shall cease,
        and you shall immediately stop accessing and using the Service, except that (a) all obligations that
        accrued prior to the effective date of termination (including payment obligations) and all remedies for
        breach of the Terms of Service shall survive, (b) AutoMarketplace will reasonably cooperate with completing
        any Vehicle Services booked prior to termination (including collecting and distributing payments),
        (c) AutoMarketplace may retain and use non-personally identifiable User Data and (d) the provisions of
        the section entitled Advertising and Promotions, and all following sections herein, shall survive. After
        termination, AutoMarketplace has no obligation to maintain any User Data or User Content or to forward
        any unread or unsent messages to you or to any other user or third party.
      </p>
      <p>
        No Warranties: THE SITE, APPS, CONTENT AND ALL OTHER ASPECTS OF THE SERVICE ARE PROVIDED “AS IS” AND “AS
        AVAILABLE”. THE SITE, APPS, CONTENT AND ALL OTHER ASPECTS OF THE SERVICE ARE PROVIDED WITHOUT REPRESENTATION
        OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OF
        ACCURACY, INTEGRATION, TITLE, NON-INFRINGEMENT, QUIET ENJOYMENT, MERCHANTABILITY AND FITNESS FOR A
        PARTICULAR PURPOSE, AND ALL WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL
        OF WHICH ARE EXPRESSLY DISCLAIMED. AutoMarketplace AND ITS AFFILIATES, LICENSORS AND BUSINESS PARTNERS
        DO NOT WARRANT THAT: (A) ANY INFORMATION WILL BE TIMELY, ACCURATE, RELIABLE OR CORRECT; (B) THE SERVICE
        WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR PLACE; (C) ANY SERVICE DEFECTS OR ERRORS WILL BE
        CORRECTED; (D) THE SERVICE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (E) ANY RESULT OR
        OUTCOME CAN BE ACHIEVED. USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.
      </p>
      <p>
        ALL REPRESENTATIONS AND WARRANTIES REGARDING ANY VEHICLE SERVICES SHALL BE, IF AT ALL,
        ONLY AS EXPRESSLY AGREED BETWEEN YOU AND THE APPLICABLE MECHANIC.
      </p>
      <p>
        Limitation of Liability: You agree that AutoMarketplace (and its affiliates, licensors and Business
        Partners) shall not be responsible or liable for any unauthorized access to, alteration or use of
        your account, transmissions or data, or for any material or data sent or received or not sent or
        received through the Service. You agree that AutoMarketplace (and its affiliates, licensors and Business
        Partners) shall not be responsible or liable for any threatening, defamatory, obscene, offensive,
        illegal or other content or conduct of any user or any user’s infringement of another’s rights,
        including intellectual property rights, rights of publicity or privacy rights.
      </p>
      <p>
        IN NO EVENT SHALL AUTOMARKETPLACE (OR ITS AFFILIATES, LICENSORS AND BUSINESS PARTNERS) BE LIABLE
        CONCERNING ANY SUBJECT MATTER RELATED TO THE SITE, APPS, CONTENT OR OTHER ASPECTS OF THE SERVICE,
        REGARDLESS OF THE FORM OF ANY CLAIM OR ACTION (WHETHER IN CONTRACT, NEGLIGENCE, STRICT LIABILITY
        OR OTHERWISE), FOR ANY (A) MATTER BEYOND ITS REASONABLE CONTROL, (B) LOSS OR INACCURACY OF DATA,
        LOSS OR INTERRUPTION OF USE, OR COST OF PROCURING SUBSTITUTE TECHNOLOGIES, GOODS OR SERVICES, (C)
        INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES INCLUDING,
        BUT NOT LIMITED TO, LOSS OF BUSINESS, REVENUES, PROFITS OR GOODWILL, OR (D) DAMAGES, IN THE AGGREGATE,
        IN EXCESS OF AMOUNTS YOU PAID TO AutoMarketplace FOR THE SERVICE OR US$100.00, WHICHEVER IS GREATER,
        EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. AutoMarketplace’S MAXIMUM LIABILITY
        FOR ANY CLAIM OR ACTION RELATED TO ANY VEHICLE SERVICES SHALL NOT EXCEED THE SERVICE FEE RETAINED BY
        AUTOMARKETPLACE FOR THE VEHICLE SERVICES GIVING RISE TO YOUR DAMAGES. THESE LIMITATIONS ARE INDEPENDENT
        FROM ALL OTHER PROVISIONS OF THIS AGREEMENT AND SHALL APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY
        PROVIDED HEREIN.
      </p>
      <p>
        SOME STATES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
        INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
      </p>
      <p>
        Indemnification You agree to (a) defend AutoMarketplace, its affiliates, licensors and Business
        Partners, and their officers, directors, employees, contractors and representatives (Indemnitees)
        against any action or suit by a third party that arises out of any transaction or other dealings
        between you and any other user or Business Partner, your use or misuse of the Service, or your breach
        of any of your representations, warranties or covenants under the Terms of Service and (b) indemnify
        the Indemnitees for liabilities (resulting from settlements or judgment awards), costs and expenses
        (including without limitation, reasonable attorneys’ fees) related to such claims. In addition,
        AutoMarketplace reserves the right to assume the exclusive defense and control of any matter subject
        to the foregoing indemnity, in which event you will assist and cooperate with AutoMarketplace in
        asserting any available defenses.
      </p>
      <p>
        Governing Law; Arbitration: A printed version of the Terms of Service and of any notice given in
        electronic form shall be admissible in judicial or administrative proceedings based upon or relating
        to the Terms of Service (including any Policy) to the same extent and subject to the same conditions
        as other business documents and records originally generated and maintained in printed form. You and
        AutoMarketplace agree that any claim or cause of action arising out of or related to the Service must
        be commenced within 1 year after the claim or cause of action arose. Otherwise, such a claim or cause
        of action is permanently barred.
      </p>
      <p>
        The Terms of Service shall be governed by and construed in accordance with the laws of the State of
        New York, USA, excluding its conflicts of law rules. In the event of any conflict between US and foreign
        laws, regulations and rules, US laws, regulations and rules shall govern. The United Nations Convention
        on Contracts for the International Sale of Goods shall not apply to the Terms of Service.
      </p>
      <p>
        Except that either party may seek an injunction or other equitable relief from any court of competent
        jurisdiction, all disputes between the parties arising out of or in relation to or in connection with the
        Terms of Service shall be settled by binding arbitration in accordance with the JAMS streamlined arbitration
        rules and procedures then in force, by one neutral arbitrator appointed in accordance with the rules.
        The arbitration shall take place in New York, New York, USA. The proceedings shall be in English, all evidence
        shall be in English (or translated into English) and the governing law shall be as set forth herein.
        The arbitrator’s decision shall be in writing and shall comply with all terms and conditions in the
        applicable version of the Terms of Service. The decision and award rendered shall be final and binding
        on both parties. The parties acknowledge and agree that the Terms of Service and any award rendered pursuant
        hereto shall be governed by the UN Convention on the Recognition and Enforcement of Foreign Arbitral
        Awards. Judgment on the award may be entered in any court of competent jurisdiction.
      </p>
      <p>
        Use of the Service is not authorized in any jurisdiction that does not give effect to all provisions
        of the Terms of Service, including without limitation, this section.
      </p>
      <p>
        Integration: These Terms of Service (including the then current Policies) are the entire agreement
        between you and AutoMarketplace with respect to access, use and operation of the Service, and supersede
        all prior or contemporaneous communications, proposals and agreements (whether oral, written or electronic)
        between you and AutoMarketplace with respect to the Service. If any provision of the Terms of Service is
        found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent
        necessary so that the Terms of Service will otherwise remain in full force and effect and enforceable.
      </p>
      <p>
        General Provisions: Your rights and obligations under the Terms of Service are personal to you, and 
        are not assignable, transferable or sublicensable by you except with AutoMarketplace’s prior written 
        consent. AutoMarketplace may subcontract, delegate, assign or otherwise transfer any or all of its 
        rights and obligations hereunder without your consent. All waivers, consents and modifications must 
        be in a writing signed by both parties, except as otherwise provided herein. No agency, partnership, 
        joint venture, or employment relationship is created as a result of the Service or Terms of Service, 
        and neither party has any authority of any kind to bind the other in any respect. All notices under 
        these Terms of Service will be in writing, in English and will be deemed to have been duly given when 
        received, if personally delivered or sent by certified or registered mail or express courier, return 
        receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
