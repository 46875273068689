/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import Collapsible from 'react-collapsible';
import { IconArrowHead } from '../../components';
import { useState } from 'react';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  ExternalLink,
} from '../../components';
import { FaPlus } from 'react-icons/fa6';
import { RiSubtractLine } from 'react-icons/ri';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    isDomainAutoMarketplace,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const [aboutActive, setAboutActive] = useState(null);
  const [activeThird, setActiveThird] = useState(true);
  const [active, setActive] = useState(true);
  const [activeSecond, setActiveSeond] = useState(true);
  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          {/* <Collapsible
            open={true}
            trigger={
              <div className={css.settings}>
                <span>
                  <FormattedMessage id="TopbarMobileMenu.siteInfo" />
                </span>
                <span className={css.arrowIcon}>
                  {' '}
                  <IconArrowHead
                    direction={aboutActive ? 'up' : 'down'}
                    rootClassName="arrowIcon"
                    size="big"
                  />
                </span>
              </div>
            }
            onOpen={() => setAboutActive('up')}
            onClose={() => setAboutActive(null)}
          >
           
          </Collapsible> */}
          <NamedLink name="AboutPage" className={css.aboutUs}>
            <FormattedMessage id="TopbarMobileMenu.aboutUsLink" />
          </NamedLink>

          <NamedLink name="Service" className={css.HowItWorks}>
            Services
          </NamedLink>
          <NamedLink className={css.HowItWorks} name="BNPLPage">
            Web3
          </NamedLink>

          <ExternalLink href="https://automarketplace.substack.com/" className={css.newsletter}>
            <FormattedMessage id="TopbarMobileMenu.newsletter" />
          </ExternalLink>
          <div className={css.yellowLine} />
          <Collapsible
            open={true}
            trigger={
              <div className={css.settings}>
                <span className={css.category}>Categories</span>
                <span className={css.arrowIcon}>
                  {activeThird ? (
                    <RiSubtractLine className={css.plusIconC} />
                  ) : (
                    <FaPlus
                      direction={active ? 'up' : 'down'}
                      className={css.plusIconC}
                      // rootClassName="arrowIcon" size="small"
                    />
                  )}
                  {/* {' '}
                <IconArrowHead
                  direction={aboutActive ? 'up' : 'down'}
                  rootClassName="arrowIcon"
                  size="big"
                /> */}
                </span>
              </div>
            }
            onOpen={() => setActiveThird(!activeThird)}
            onClose={() => setActiveThird(false)}
          >
            <NamedLink
              name="SearchPage"
              to={{
                search: '?pub_category=tlc_insurance',
              }}
              className={css.aboutUs}
            >
              TLC Insurance
            </NamedLink>
            <br />
            <NamedLink
              name="SearchPage"
              to={{
                search: '?pub_category=dmv_services',
              }}
              className={css.aboutUs}
            >
              DMV Services
            </NamedLink>
            <br />
            <NamedLink
              name="SearchPage"
              to={{
                search: '?pub_category=tlc_services',
              }}
              className={css.aboutUs}
            >
              TLC Services
            </NamedLink>
            <br />
            <NamedLink
              name="SearchPage"
              to={{
                search: '?pub_category=tlc_plate_rentals',
              }}
              className={css.aboutUs}
            >
              TLC Plate Rentals
            </NamedLink>
            <br />
            <NamedLink
              name="SearchPage"
              to={{
                search: '?pub_category=tlc_car_rentals',
              }}
              className={css.aboutUs}
            >
              TLC Car Rentals
            </NamedLink>
            <br />
            <NamedLink
              name="SearchPage"
              to={{
                search: '?pub_category=taxi_medallions',
              }}
              className={css.aboutUs}
            >
              Taxi Medallions
            </NamedLink>
            <br />
            <NamedLink
              name="SearchPage"
              to={{
                search: '?pub_category=taxi_garages',
              }}
              className={css.aboutUs}
            >
              Taxi Garages
            </NamedLink>
            <br />
            <NamedLink
              name="SearchPage"
              to={{
                search: '?pub_category=black_car_bases',
              }}
              className={css.aboutUs}
            >
              Black Car Bases
            </NamedLink>
          </Collapsible>
        </div>

        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div> */}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  // const [activeThird, setActiveThird] = useState(true);
  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {isDomainAutoMarketplace ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        ) : null}

        <Collapsible
          open={true}
          trigger={
            <div className={css.settings}>
              <span>
                <FormattedMessage id="TopbarMobileMenu.settings" />{' '}
              </span>
              <span className={css.arrowIcon}>
                {' '}
                {active ? (
                  <RiSubtractLine className={css.plusIcon} />
                ) : (
                  <FaPlus
                    direction={active ? 'up' : 'down'}
                    className={css.plusIcon}
                    // rootClassName="arrowIcon" size="small"
                  />
                )}
              </span>
            </div>
          }
          onOpen={() => setActive(!active)}
          onClose={() => setActive(false)}
        >
          <NamedLink
            className={classNames(
              css.manageYourMaintenance,
              currentPageClass('ProfileSettingsPage')
            )}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>{' '}
          <br />
          <NamedLink
            className={classNames(
              css.manageYourMaintenance,
              currentPageClass('AccountSettingsPage')
            )}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
        </Collapsible>

        <Collapsible
          open={true}
          trigger={
            <div className={css.settings}>
              <span>
                <FormattedMessage id="TopbarMobileMenu.siteInfo" />{' '}
              </span>
              <span className={css.arrowIcon}>
                {activeSecond ? (
                  <RiSubtractLine className={css.plusIcon} />
                ) : (
                  <FaPlus
                    direction={active ? 'up' : 'down'}
                    className={css.plusIcon}
                    // rootClassName="arrowIcon" size="small"
                  />
                )}
                {/* {' '}
                <IconArrowHead
                  direction={aboutActive ? 'up' : 'down'}
                  rootClassName="arrowIcon"
                  size="big"
                /> */}
              </span>
            </div>
          }
          onOpen={() => setActiveSeond(!activeSecond)}
          onClose={() => setActiveSeond(false)}
        >
          <NamedLink name="AboutPage" className={css.aboutUs}>
            <FormattedMessage id="TopbarMobileMenu.aboutUsLink" />
          </NamedLink>
          {/* <br />
          <NamedLink name="Web3MarketplacePage" className={css.web3Marketplace}>
            <FormattedMessage id="TopbarMobileMenu.web3MarketplaceLink" />
          </NamedLink> */}
          <br />
          <NamedLink name="Service" className={css.HowItWorks}>
            Services
          </NamedLink>{' '}
          <br />
          <NamedLink className={css.HowItWorks} name="BNPLPage">
            Web3
          </NamedLink>
          {/* <NamedLink name="BNPLPage" className={css.bnplPage}>
            <FormattedMessage id="TopbarMobileMenu.BNPLLink" />
          </NamedLink>
          <br /> */}
          {/* <NamedLink name="AutomktRewards" className={css.automktRewards}>
            <FormattedMessage id="TopbarMobileMenu.AUTOMKTRewardsLink" />
          </NamedLink>
          <br /> */}
          {/* <NamedLink name="ManageYourMaintenance" className={css.manageYourMaintenance}>
            <FormattedMessage id="TopbarMobileMenu.ManagingYourMaintenanceLink" />
          </NamedLink><br /> */}
          {/* <NamedLink name="ElectricVehicle" className={css.evPage}>
            <FormattedMessage id="TopbarMobileMenu.EVs" />
          </NamedLink> */}
          <br />
          {/* <NamedLink name="DataAndResearch" className={css.dataPage}>
            <FormattedMessage id="TopbarMobileMenu.DataAndResearch" />
          </NamedLink>
          <br /> */}
          <ExternalLink href="https://automarketplace.substack.com/" className={css.newsletter}>
            <FormattedMessage id="TopbarMobileMenu.newsletter" />
          </ExternalLink>
        </Collapsible>
        <Collapsible
          open={true}
          trigger={
            <div className={css.settings}>
              <span>Categories</span>
              <span className={css.arrowIcon}>
                {activeThird ? (
                  <RiSubtractLine className={css.plusIcon} />
                ) : (
                  <FaPlus
                    direction={active ? 'up' : 'down'}
                    className={css.plusIcon}
                    // rootClassName="arrowIcon" size="small"
                  />
                )}
                {/* {' '}
                <IconArrowHead
                  direction={aboutActive ? 'up' : 'down'}
                  rootClassName="arrowIcon"
                  size="big"
                /> */}
              </span>
            </div>
          }
          onOpen={() => setActiveThird(!activeThird)}
          onClose={() => setActiveThird(false)}
        >
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=tlc_insurance',
            }}
            className={css.aboutUs}
          >
            TLC Insurance
          </NamedLink>
          <br />
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=dmv_services',
            }}
            className={css.aboutUs}
          >
            DMV Services
          </NamedLink>
          <br />
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=tlc_services',
            }}
            className={css.aboutUs}
          >
            TLC Services
          </NamedLink>
          <br />
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=tlc_plate_rentals',
            }}
            className={css.aboutUs}
          >
            TLC Plate Rentals
          </NamedLink>
          <br />
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=tlc_car_rentals',
            }}
            className={css.aboutUs}
          >
            TLC Car Rentals
          </NamedLink>
          <br />
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=taxi_medallions',
            }}
            className={css.aboutUs}
          >
            Taxi Medallions
          </NamedLink>
          <br />
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=taxi_garages',
            }}
            className={css.aboutUs}
          >
            Taxi Garages
          </NamedLink>
          <br />
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=black_car_bases',
            }}
            className={css.aboutUs}
          >
            Black Car Bases
          </NamedLink>
        </Collapsible>
      </div>
      {isDomainAutoMarketplace ? (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      ) : null}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
