import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionPrepaidMaintenancePackages.module.css';
import prepaidMaintenance from './images/prepaidMaintenance.png';
import { string } from 'prop-types';
import { NamedLink } from '../../components';

const SectionFinancingAutoRepair = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionPrepaidMaintenancePackages.title" />
      </h1>
      <div className={css.contentWrapper}>
        <div>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionPrepaidMaintenancePackages.paragraph1" /> */}
            We’ve teamed up with select shops to offer unique prepaid maintenance packages for NYC
            area drivers. The goal is to help drivers and fleets save money by locking in
            competitive deals through prepaying for routine auto services (i.e., state inspections,
            oil changes, brake flushes, tire rotation)
          </p>
          {/* <p className={css.paragraph}>
            <FormattedMessage id="SectionPrepaidMaintenancePackages.paragraph2" />
            <NamedLink name="ManageYourMaintenance">
              <FormattedMessage id="SectionPrepaidMaintenancePackages.here" />
            </NamedLink>
            <FormattedMessage id="SectionPrepaidMaintenancePackages.toLearnMore" />
          </p> */}
        </div>
        <img className={css.image} src={prepaidMaintenance} alt="Prepaid Maintenance of your car" />
      </div>
    </div>
  );
};

SectionFinancingAutoRepair.defaultProps = { rootClassName: null, className: null };

SectionFinancingAutoRepair.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFinancingAutoRepair;
