import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { IconPlay, Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionYoutubeChannel.module.css';
import youtubeIcon from '../../assets/youtube.png';

const SectionYoutubeChannel = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  };
  return (
    <div className={classes}>
      <div className={css.youtubeWrapper}>
        <div className={css.youtubeContent}>
          <div>
            <div className={css.youtubeHeader}>
              <div className={css.icon}><img src={youtubeIcon} alt="youtube icon" /></div>
              <h1><FormattedMessage id="SectionYoutubeAutomarketplace" />
                <a href="https://www.youtube.com/channel/UCBLspAbS3IQu-w9QaEaVyQg">
                  <span className={css.youtube}><FormattedMessage id="SectionYoutube" /></span>
                </a>
              </h1>

            </div>
            <p>
              <FormattedMessage id="SectionYoutubeChannel.paragraph" />
            </p>

            <iframe
                  className={css.video}
                  src="https://www.youtube.com/embed/2aC2onaQGKE"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  title="video">
              </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionYoutubeChannel.defaultProps = { rootClassName: null, className: null };

SectionYoutubeChannel.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionYoutubeChannel;
