import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionWarrantyOnAllWork.module.css';
import guarantee from './images/guarantee.png';
import { string } from 'prop-types';

const SectionWarrantyOnAllWork = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionWarrantyOnAllWork.title" />
      </h1>
      <div className={css.contentWrapper}>
        <div>
          <p className={css.paragraph}>
            <FormattedMessage id="SectionWarrantyOnAllWork.paragraph" />
          </p>
        </div>
        {/* <img className={css.image} src={guarantee} alt="Clock" /> */}
      </div>
    </div>
  );
};

SectionWarrantyOnAllWork.defaultProps = { rootClassName: null, className: null };

SectionWarrantyOnAllWork.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWarrantyOnAllWork;
