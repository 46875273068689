import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import css from './SectionHero.module.css';
import ListingCard from '../ListingCard/ListingCard';
import IconSpinner from '../IconSpinner/IconSpinner';
import { useIntl } from 'react-intl';
import { IoMdArrowDropleft } from 'react-icons/io';
import { IoMdArrowDropright } from 'react-icons/io';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';

const SectionListings = props => {
  const { promotedListings = [], error, loading } = props;
  const intl = useIntl();
  if (error) {
    return (
      <div>
        <span className={css.error}>
          {intl.formatMessage({ id: 'LandingPage.SectionFeaturedListingserror' })}
        </span>
      </div>
    );
  }

  const renderArrowPrev = (onClickHandler, hasPrev, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      className={css.leftArrow}
      aria-label={label}
      disabled={!hasPrev}
    >
      <IoIosArrowBack className={css.left} />
    </button>
  );

  const renderArrowNext = (onClickHandler, hasNext, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      className={css.rightArrow}
      aria-label={label}
      disabled={!hasNext}
    >
      <IoIosArrowForward className={css.icon} />
    </button>
  );

  if (loading) {
    return (
      <div>
        <p>
          <IconSpinner style={{ style: { height: '50px', width: '50px' } }} />
        </p>
      </div>
    );
  }
  if (promotedListings.length === 0) {
    return (
      <div>
        <span className={css.noResults}>
          {intl.formatMessage({ id: 'LandingPage.SectionFeaturedListingsnoResults' })}
        </span>
      </div>
    );
  }

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  return (
    <div>
      <div className={css.mobile}>
        {promotedListings.map(l => (
          <div className={css.listingCardsMobile}>
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              landingPage={true}
            />
          </div>
        ))}
      </div>
      <div className={css.desktop}>
        {/* <Carousel
          autoPlay={true}
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          showIndicators={true}
          infiniteLoop={true}
          swipeable={true}
          transitionTime={1000}
          interval={10000}
          centerMode={true}
          centerSlidePercentage={100}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          // renderArrowPrev={(onClickHandler, hasPrev, label) =>
          //   hasPrev && <CustomPrevArrow onClick={onClickHandler} />
          // }
          // renderArrowNext={(onClickHandler, hasNext, label) =>
          //   hasNext && <CustomNextArrow onClick={onClickHandler} />
          // }
        > */}
        {promotedListings.map((l, index) => (
          <div className={css.desktopMain} key={index}>
            <div className={css.listingCards}>
              <ListingCard
                className={css.listingCard}
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes}
                landingPage={true}
                showSmall={true}
              />
            </div>
          </div>
        ))}
        {/* </Carousel> */}
      </div>
    </div>
  );
};

export default SectionListings;
