import React, { useState } from 'react';
import { VscFilePdf } from 'react-icons/vsc';
import css from './ListingPage.module.css';
import { Modal, ShowPdfs } from '../../components';
const SectionInvoices = props => {
  const { metadata, onManageDisableScrolling } = props;
  const { invoices = [] } = metadata || {};
  if (!invoices?.length) {
    return null;
  }
  const [showModal, setShowModal] = useState(false);
  const [invoice, setInvoice] = useState(null);

  return invoices && invoices.length > 0 ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>Example Invoices</h2>

      <div className={css.invoices}>
        {invoices.map((invoice, i) => (
          <div className={css.invoiceContainer}>
            <VscFilePdf
              className={css.pdfIcon}
              onClick={() => {
                setInvoice(invoice);
                setShowModal(true);

                // if (typeof window !== 'undefined') window.open(invoice, '_blank');
              }}
            />
          </div>
        ))}
      </div>
      <Modal
        id="invoice-modal"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        className={css.modalContent}
      >
        <div className={css.modalHeader}>
          <h1 className={css.modalTitle}>Invoice</h1>
          <ShowPdfs link={invoice} />
        </div>
      </Modal>
    </div>
  ) : null;
};

export default SectionInvoices;
