import React, { useState } from 'react';
// import { Avatar, ExternalLink, Menu, MenuContent, MenuItem, MenuLabel, NamedLink, PrimaryButton } from '../../../components';
import { FormattedMessage } from 'react-intl';
import css from './ShareButton.module.css';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { IoShareOutline } from 'react-icons/io5';
import { FaInstagram } from 'react-icons/fa';
import { LiaCopy } from 'react-icons/lia';
import Menu from '../Menu/Menu';
import MenuLabel from '../MenuLabel/MenuLabel';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
import ExternalLink from '../ExternalLink/ExternalLink';
import { PrimaryButton } from '../Button/Button';
function ShareButton(props) {
  const [copied, setCopied] = useState(false);
  const titlee = 'Check out this listing';
  let shareUrl=null;
  if (typeof window !== 'undefined') {
    shareUrl = window.location.href;
  }
  const instagramUrl = `https://www.instagram.com/?url=${encodeURIComponent(shareUrl)}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };
  return (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
        style={undefined}
      >
        <div className={css.shareButton}>
          <IoShareOutline className={css.shareIcon} />
          <div className={css.share}>Share</div>
        </div>
      </MenuLabel>
      <MenuContent isShare={true} className={css.profileMenuContentt} key="topbardesktop">
        <MenuItem key="instagram">
          <ExternalLink className={css.instaShare} href={instagramUrl}>
            <div style={{ cursor: 'pointer' }}>
              <FaInstagram style={{ color: 'var(--marketplaceColor)' }} size={32} round />
              <span>Instagram</span>
            </div>
          </ExternalLink>
        </MenuItem>
        <MenuItem key="facebook">
          <div>
            <FacebookShareButton url={shareUrl} quote={titlee}>
              <FacebookIcon size={32} round />
              <span>Facebook</span>
            </FacebookShareButton>
          </div>
        </MenuItem>
        <MenuItem key="whatsapp">
          <div>
            <WhatsappShareButton url={shareUrl} title={titlee}>
              <WhatsappIcon size={32} round />
              <span>Whatsapp</span>
            </WhatsappShareButton>
          </div>
        </MenuItem>
        <MenuItem key="linkedIn">
          <div>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={32} round />
              <span>LinkedIn</span>
            </LinkedinShareButton>
          </div>
        </MenuItem>
        <MenuItem key="copyLink" onClick={() => console.log('')}>
          <PrimaryButton className={css.copyLink} onClick={handleCopyLink}>
            <LiaCopy className={css.copyIcon} size={32} />
            <span>{copied ? 'Link Copied' : 'Copy Link'}</span>
          </PrimaryButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}
export default ShareButton;
