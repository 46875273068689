import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';

import css from './DataAndResearchPage.module.css';

const DataAndResearchPage = () => {

  return (
    <StaticPage
      title="Data And Research"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'DataAndResearchPage',
        description: 'Data And Research Page',
        name: 'Data And Research Page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentMain}>
            <h1 className={css.pageTitle}><span className={css.span}>D</span>ata And Research</h1>
            <p className={css.paragraphContent}>An informed individual is a better customer. We analyze data to help better inform our users about local automotive trends.</p>
            <div className={css.buttonContainer}>
              <NamedLink className={css.buttonLink} name="DataAndResearch">
                <button className={css.currentOffersBtn}>Coming Soon
                </button>
              </NamedLink>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default DataAndResearchPage;