import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldTextInput } from '../../components';
import css from './EditListingPricingForm.module.css';
import {
  moneySubUnitAmountAtLeast,
  validDiscount,
  required,
  composeValidators,
  validateRating,
} from '../../util/validators';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const partsInputMessage = intl.formatMessage({
        id: 'EditListingPricingForm.partsInput',
      });

      const partsInputPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.partsInputPlaceholder',
      });

      const discountInputMessage = intl.formatMessage({
        id: 'EditListingPricingForm.discountInput',
      });

      const discountInputPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.discountInputPlaceholder',
      });

      const taxInputMessage = intl.formatMessage({
        id: 'EditListingPricingForm.taxInput',
      });

      const taxInputPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.taxInputPlaceholder',
      });

      const priceRequired = intl.formatMessage({
        id: 'EditListingPricingForm.priceRequired',
      });

      const discountInvalid = intl.formatMessage({
        id: 'EditListingricingForm.DiscountInvalid',
      });

      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);

      const lowPriceMsg = intl.formatMessage(
        {
          id: 'EditListingPricingForm.priceTooLow',
        },
        {
          minPrice: formatMoney(intl, minPrice),
        }
      );
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldTextInput
            id="am_rating"
            name="am_rating"
            className={css.priceInput}
            autoFocus
            label="AM Rating"
            placeholder="Enter AM Rating"
            validate={composeValidators(
              required('AM Rating is required'),
              validateRating('AM Rating must be between 0 and 5')
            )}
          />
          {/* <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={composeValidators(required(priceRequired), moneySubUnitAmountAtLeast(lowPriceMsg,config.listingMinimumPriceSubUnits))}
          />
          <FieldCurrencyInput
            id="parts"
            name="parts"
            className={css.priceInput}
            label={partsInputMessage}
            placeholder={partsInputPlaceholderMessage}
            currencyConfig={config.currencyConfig}
          />
          <FieldCurrencyInput
            id="discount"
            name="discount"
            className={css.priceInput}
            label={discountInputMessage}
            placeholder={discountInputPlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={composeValidators(validDiscount(discountInvalid))}
          />
          <FieldCurrencyInput
            id="tax"
            name="tax"
            className={css.priceInput}
            label={taxInputMessage}
            placeholder={taxInputPlaceholderMessage}
            currencyConfig={config.currencyConfig}
          /> */}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
