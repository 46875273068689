import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelectModern } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategoriesSelectFieldMaybe';
import CustomFieldsMaybe from './CustomFieldsMaybe';
import config from '../../config';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';

let draftToHtml = null;
let Editor = null;

const importEditorDependecies = () => {
  import('draftjs-to-html').then(h => {
    draftToHtml = h.default;
  });

  import('react-draft-wysiwyg').then(h => {
    Editor = h.Editor;
  });
};

typeof window !== 'undefined' ? importEditorDependecies() : null;
import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 25;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        category,
        setCategory,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        editorState,
        setEditorState,
        values,
      } = formRenderProps;

      if (!Editor) {
        return;
      }
      const onEditorStateChange = editorState => {
        setEditorState(editorState);
        const descriptionValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        formRenderProps.form.change('description', descriptionValue);
      };
      const categoriesConfig = config.custom.categoryForListingPage;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });

      const applicableModelsMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.applicableModels',
      });
      const applicableModelsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.applicableModelsPlaceholder',
      });

      const warrantyMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.warranty',
      });
      const warrantyPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.warrantyPlaceholder',
      });

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const categoryRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryRequired',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !values?.category;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          {/* <FieldSelectModern
            id="categories"
            name="categories"
            className={css.title}
            label="Category"
            placeholder="Select Category"
            options={categoriesConfig}
            validate={composeValidators(required(categoryRequiredMessage))}
            isMulti={true}
          /> */}

          <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            category={category}
            certificateOptions={categoriesConfig}
            handleCategoryChange={setCategory}
            validate={composeValidators(required(categoryRequiredMessage))}
            intl={intl}
          />

          <FieldTextInput
            inputRootClass={css.inputFontSize}
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />
          <h4 className={css.descriptionLabel}>{descriptionMessage}</h4>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName={css.editorClass}
            onEditorStateChange={onEditorStateChange}
            placeholder={descriptionPlaceholderMessage}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'remove',
                'history',
              ],
              fontFamily: {
                options: [
                  'Arial',
                  'Georgia',
                  'Impact',
                  'Tahoma',
                  'Times New Roman',
                  'Verdana',
                  'Sans-serif',
                ],
              },
            }}
          />
          <FieldTextInput
            inputRootClass={css.inputFontSize}
            id="description"
            name="description"
            className={css.hiddenDescription}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />
          <div className={css.divider}></div>

          <FieldTextInput
            inputRootClass={css.inputFontSize}
            id="applicableModels"
            name="applicableModels"
            className={css.description}
            type="text"
            label={applicableModelsMessage}
            placeholder={applicableModelsPlaceholderMessage}
          />

          <FieldTextInput
            inputRootClass={css.inputFontSize}
            id="warranty"
            name="warranty"
            className={css.description}
            type="text"
            label={warrantyMessage}
            placeholder={warrantyPlaceholderMessage}
          />

          {/* <CustomFieldsMaybe category={category} categories={categoriesConfig} /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
