import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionWeb3Marketplace.module.css';
import pays200Receives195 from './images/pays200Receives195.svg';
import { string } from 'prop-types';

const SectionWeb3Marketplace = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionWeb3Marketplace.title1.1" />
      </h1>
      {/* <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionWeb3Marketplace.title1.2" />
      </h1> */}
      <div className={css.contentWrapper}>
        <div>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionWeb3Marketplace.paragraph1.1" />
            <b>
              <FormattedMessage id="SectionWeb3Marketplace.paragraph1.2" />
            </b>
            <FormattedMessage id="SectionWeb3Marketplace.paragraph1.3" /> */}
            <b>
              The vision for AutoMarketplace.com, is our users who help build and interact with the
              platform, own most of the business
            </b>
            . In this vision, ownership is earned as part of a unique rewards program built using
            blockchain (‘crypto’) tokens.
          </p>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionWeb3Marketplace.paragraph2" /> */}
            <b>
              Users will never pay for shares; they will earn shares in a similar manner to earning
              reward points.
            </b>{' '}
            In addition, we also believe a single digit commission structure (5%) helps shops retain
            more of their income, allowing them to offer more competitive pricing. This in turn
            drives more transaction volumes, which increases the value of the marketplace, which is
            circularly owned by users.
          </p>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionWeb3Marketplace.paragraph3" /> */}
            For example, every time a user completes a transaction on AutoMarketplace.com, not only
            do they get the service or product they need, but they (& the vendor) earn an ownership
            stake in AutoMarketplace’s rewards program. It's a unique incentive structure meant to
            reward buyers and sellers of goods and services that help build and maintain
            AutoMarketplace.{' '}
            <b>
              Effective costs for both sides of the transaction decline. Buyers & sellers are
              incentivized to use the marketplace they have a stake in
            </b>
            .
          </p>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionWeb3Marketplace.paragraph4" /> */}
            Management would retain a voting majority, but minority equity position to help run the
            company (i.e., strategy, marketing, managing disputes). Digital tokens, created via
            blockchain technology, help facilitate this incentive structure.
          </p>
        </div>
        <img className={css.pays200Receives195} src={pays200Receives195} alt="buyer pays $200" />
      </div>
    </div>
  );
};

SectionWeb3Marketplace.defaultProps = { rootClassName: null, className: null };

SectionWeb3Marketplace.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWeb3Marketplace;
