import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionAutoMktTokensAndRegulations.module.css';
import cryptoCurrency from './images/cryptoCurrency.png';
import { string } from 'prop-types';

const SectionAutoMktTokensAndRegulations = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionAutoMktTokensAndRegulations.title" />
      </h1>
      <div className={css.contentWrapper}>
        <div>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionAutoMktTokensAndRegulations.paragraph1" /> */}
            As the regulatory environment related to blockchain tokens (crypto assets) is evolving,
            &nbsp;
            <b>
              we want to ensure any token structure we build is in compliance with federal and state
              laws and regulations
            </b>
            . Although a user will never pay for a token, a conservative interpretation of certain
            existing laws may deem them securities subject to a host of securities regulations.
          </p>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionAutoMktTokensAndRegulations.paragraph2" /> */}
            Therefore, our path to making AutoMarketplace user owned, via tokens, is through a
            rewards program built on the blockchain.
            <b>
              {' '}
              A blockchain token enabled rewards program is clearly allowed under current
              regulations. The rewards program will not only serve as the foundational building
              block of AutoMarketplace's ultimate value, but also have benefits similar to other
              reward programs, such as discounts on goods & services
            </b>
            .
          </p>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionAutoMktTokensAndRegulations.paragraph3" /> */}
            Once all reward tokens are earned, we will apply for a NY State BitLicense, &nbsp;
            <b>
              This will allow us to officially distribute (known as an “Airdrop”) and list the
              tokens 🪙 on well-known crypto exchanges, such as Coinbase
            </b>
            . We believe as the crypto asset class gains mass adoption in the coming years,
            regulations will become clearer.
          </p>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionAutoMktTokensAndRegulations.paragraph4" /> */}
            <b>
              Ultimately the value of AutoMarketplace tokens will be related to not only the
              profitability of AutoMarketplace, but also the value of the rewards program and
              community
            </b>
            .
          </p>
        </div>
        {/* <img className={css.image} src={cryptoCurrency} alt="Crypto Currency" /> */}
      </div>
    </div>
  );
};

SectionAutoMktTokensAndRegulations.defaultProps = { rootClassName: null, className: null };

SectionAutoMktTokensAndRegulations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAutoMktTokensAndRegulations;
