import React from 'react';
import css from './ShowPdfs.module.css';
const ShowPdfs = props => {
  const { link } = props;

  if (!link) {
    // Handle missing link gracefully
    return <div>PDF link is missing.</div>;
  }

  return (
    <div
      className={css.pdfViewer}
      // style={{
      //   width: '100%',
      //   height: '600px',
      //   maxWidth: '800px', // Limit the maximum width for larger screens
      //   margin: '0 auto', // Center the viewer horizontally
      //   overflow: 'auto', // Add scrollbar for smaller screens
      // }}
    >
      <iframe title="PDF Viewer" src={link} width="100%" height="100%" style={{ border: 'none' }} />
    </div>
  );
};

export default ShowPdfs;
