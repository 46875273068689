// import React from 'react';
// import { FieldSelect } from '../../components';
// import css from './EditListingDescriptionForm.module.css';

// class CustomCategoriesSelectFieldMaybe extends React.Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     const { id, name, categories, category, handleCategoryChange, validate, intl } = this.props;
//     const categoryLabel = intl.formatMessage({
//       id: 'EditListingDescriptionForm.categoryLabel',
//     });

//     const selectCategory = intl.formatMessage({
//       id: 'EditListingDescriptionForm.selectCategory',
//     });

//     return (
//       <div>
//         <FieldSelect
//           className={css.category}
//           name={name}
//           id={id}
//           label={categoryLabel}
//           onChange={e => handleCategoryChange(e)}
//           validate={validate}
//         >
//           <option disabled value="">
//             {selectCategory}
//           </option>
//           {categories.map(c =>
//             c.hideFromFilters ? (
//               <option key={c.key} value={c.key}>
//                 {c.label}
//               </option>
//             ) : (
//               <optgroup label={c.label}>
//                 {c.subCategories &&
//                   c.subCategories.map(subCategory => (
//                     <option key={subCategory.key} value={subCategory.key}>
//                       {subCategory.label}
//                     </option>
//                   ))}
//               </optgroup>
//             )
//           )}
//         </FieldSelect>
//       </div>
//     );
//   }
// }

// export default CustomCategoriesSelectFieldMaybe;
import React from 'react';
import { FieldSelect, FieldSelectModern } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const CustomCertificateSelectFieldMaybe = props => {
  const { name, id, certificateOptions, intl } = props;
  const certificateLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.categoryLabel',
  });

  return certificateOptions ? (
    <FieldSelectModern
      className={css.category}
      name={name}
      id={id}
      options={certificateOptions}
      label={certificateLabel}
      isMulti
    />
  ) : //   <option key="select" value="">
  //     Select
  //   </option>
  //   {certificateOptions.map(c => (
  //     <option key={c.key} value={c.key}>
  //       {c.label}
  //     </option>
  //   ))}
  // </FieldSelect>
  null;
};

export default CustomCertificateSelectFieldMaybe;
