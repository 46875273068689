import React from 'react';
import css from './ListingPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import { MdCategory } from 'react-icons/md';
import { BiCategoryAlt } from 'react-icons/bi';
const SectionCategoryMaybe = props => {
  const { publicData } = props;
  const { category } = publicData;
  if (!category) return null;
  const categoryKey = config.custom.subCategoryForListingPage?.find(c => c.key === category)
    ?.category;
  const subCategory = config.custom.subCategoryForListingPage?.find(c => c.key === category)?.label;
  const sectionCategory =
    category && categoryKey ? (
      <div className={css.sectionDescription}>
        <div className={css.iconIWrapper}>
          <MdCategory className={css.categoryIcon} />
          <h2 className={css.descriptionTitle}>
            <FormattedMessage id="ListingPage.categoryTitle" />
          </h2>
        </div>

        <p>{config.custom.categoryForListingPage?.find(i => i.key === categoryKey)?.label}</p>
      </div>
    ) : null;
  const sectionSubCategory = subCategory ? (
    <div className={css.sectionDescription}>
      <div className={css.iconIWrapper}>
        <BiCategoryAlt className={css.categoryIcon} />
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.subCategoryTitle" />
        </h2>
      </div>

      <p>{subCategory}</p>
    </div>
  ) : null;
  return (
    <>
      {sectionCategory}
      {sectionSubCategory}
    </>
  );
};

export default SectionCategoryMaybe;
