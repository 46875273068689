import React from 'react';
import { Field } from 'react-final-form';
import { IconSpinner } from '../../components';
const inputStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
};

const ACCEPT_FILES = 'application/pdf';

function FieldFileUpload(props) {
  const {
    name,
    onSelectFile,
    label,
    showUploading,
    parentClass,
    uploading,
    acceptFiles,
    center,
    ...restProps
  } = props;
  let accept;
  switch (acceptFiles) {
    case 'images':
      accept = 'image/png,image/jpg,image/jpeg';
      break;
    default:
      accept = ACCEPT_FILES;
  }
  return (
    <div>
      <label
        htmlFor={name}
        style={{ ...inputStyle, justifyContent: center ? 'center' : 'flex-start' }}
        className={parentClass}
      >
        {showUploading ? uploading ? <IconSpinner /> : label : label}
      </label>
      <Field name={name} accept={ACCEPT_FILES}>
        {({ input: { value, onChange, ...rest } }) => {
          const handleChange = event => {
            const file = event.target.files[0];
            // const file = event.target.files;
            onChange(file);
            if (typeof onSelectFile != 'undefined') {
              onSelectFile(file);
            }
          };
          return (
            <input
              {...rest}
              type="file"
              disabled={uploading}
              onChange={handleChange}
              accept={accept}
              {...restProps}
              style={{ display: 'none' }}
            />
          );
        }}
      </Field>
    </div>
  );
}

FieldFileUpload.defaultProps = {
  center: true,
};

export default FieldFileUpload;
