import React, { useState } from 'react';
import { IconPlay, Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  SectionTokenonmics,
  SectionAutoMktTokensAndRegulations,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';

import css from './HowItWorksPage.module.css';
import procedure from './images/procedure.png';

const HowItWorksPage = () => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {};

  return (
    <StaticPage
      title="How it works"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'HowItWorksPage',
        description: 'How Automarketplace works',
        name: 'How It Works',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentMain}>
            {/* <div className={css.blueLine}></div> */}
            <h1 className={css.heading}>
              <FormattedMessage id="HowItWorks.title" />
            </h1>
            <br />
            <p className={css.paragraph}>
              <b>AutoMarketplace.com is built for the blockchain</b>. Ownership in the marketplace’s
              rewards program is represented by crypto tokens (built on the Ethereum and Solana
              blockchains).
            </p>
            <iframe
              className={css.video}
              src="https://www.youtube.com/embed/2aC2onaQGKE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            {/* <div className={css.video}>
            <div className={css.videoContent}>
              <span onClick={handleOpen} className={css.iconPlay}><IconPlay /></span>
            </div>
            <Modal
              isOpen={isOpen}
              onClose={() => {
                setOpen(false);
              }}
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={css.modalSizeContainer}
            >
              <iframe
                className={css.video}
                src="https://www.youtube.com/embed/videoseries?controls=0&amp;modestbranding=1&amp;rel=0&amp;showinfo=0&amp;autoplay=0&amp;loop=1&amp;list=PLxq1pNllAJszxbQ4V4rMeFqsmlvGl_X4N"
                title="YouTube video player"
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
              </iframe>
            </Modal>
          </div> */}
            {/* <p className={css.paragraph}>
              <FormattedMessage id="HowItWorks.paragraph" />
            </p> */}
            {/* <div className={css.blueLine}></div> */}

            <br />

            <h1 className={css.heading}>
              <FormattedMessage id="HowItWorks.earningAUTOMKTTokensHeading" />
            </h1>
            <br />
            <p className={css.paragraph}>
              {/* <FormattedMessage id="HowItWorks.earningAUTOMKTTokensParagraph" /> */}
              <b> Automarketplace.com tokens are earned in four primary ways</b>. Our goal is for
              80% of all tokens to be owned outside management.
            </p>
            <p className={css.paragraph}>
              🛒 <b>Transactions (#)</b>
              <br /> 📊 <b>Transactions (volume)</b>
              <br /> ⭐ <b>Ratings</b>
              <br /> 🗣️<b> Referrals</b>
            </p>
            {/* <div className={css.imagesContainer}>
              <img src={procedure} alt="Four Steps Procedure" />
            </div> */}
            {/* <p className={css.earnTokens}>
          <FormattedMessage id="HowItWorks.forHowUsersSpecificallyEarnAUTOMKTToken" />
          <NamedLink name="EarningAUTOMKTTokenPage" className={css.hereLink}><FormattedMessage id="HowItWorks.here" /></NamedLink>.
          </p> */}
            <ul>
              <li>
                <div>
                  <SectionTokenonmics />
                </div>
              </li>
              <li>
                <div>
                  <SectionAutoMktTokensAndRegulations />
                </div>
              </li>
            </ul>
            <div className={css.buttonContainer}>
              <NamedLink name="AutomktRewards" className={css.buttonLink}>
                <button className={css.automktBtn}>
                  <FormattedMessage id="HowItWorks.autoMarketRewardsButton" />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </button>
              </NamedLink>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default HowItWorksPage;
