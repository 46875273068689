import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionWeb3AndUserOwnership.module.css';
import userOwnership from './images/userOwnership.png';
import { string } from 'prop-types';

const SectionWeb3AndUserOwnership = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionWeb3AndUserOwnership.title" />
      </h1>
      <div className={css.contentWrapper}>
        <div>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionWeb3AndUserOwnership.paragraph1" /> */}
            The rise of the internet (Web1) in the 1990s saw the creation of “read-only” online
            directories, a sort of digital Yellow Pages (if you remember what that was!). This laid
            the foundation for modern eCommerce (Web2) , often done on online marketplaces and
            social media sites.{' '}
            <b>
              eCommerce platforms function by charging a commission on sales, subscription fees,
              selling online ad space or a combination of the three. Today, eCommerce is going
              through another transformation (Web3).
            </b>
          </p>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionWeb3AndUserOwnership.paragraph2" /> */}
            Enabled by <b> blockchain technology</b>, eCommerce is being reimagined. Online
            marketplaces will be <b>majority owned and controlled by users</b>. Users benefit from
            growing and maintaining the marketplace.
            <b> AutoMarketplace.com is built for this new paradigm</b>.
          </p>
          {/* <p className={css.boldText}>
            <a href="/web3_marketplace" target="_blank">
              <FormattedMessage id="SectionWeb3AndUserOwnership.paragraph3" />
            </a>
          </p> */}
        </div>
        <img className={css.image} src={userOwnership} alt="A user browsing on the internet." />
      </div>
    </div>
  );
};

SectionWeb3AndUserOwnership.defaultProps = { rootClassName: null, className: null };

SectionWeb3AndUserOwnership.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWeb3AndUserOwnership;
