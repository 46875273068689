import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import LogoImage from '../../assets/logo032024.png';
import FooterLogo from './white_logo.png';
import logoText from '../../assets/logoText.png';
import css from './Logo.module.css';

const IconLogo = props => {
  const { className, variant, ...rest } = props;
  if (variant === 'footer') {
    <img className={className} src={FooterLogo} alt={config.siteTitle} {...rest} />;
  }
  return (
    <div className={css.logoContainer}>
      <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />
      <span className={css.logoText}>
        <img src={logoText} className={css.logoTextImageM} alt="AutoMarketplace" />
      </span>
    </div>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
