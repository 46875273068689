import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingTimeForm } from '../../forms';

import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingPanel.module.css';
import { formatPhoneNumber } from 'react-phone-number-input';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, partsAmount, partsCurrency, intl) => {
  if (price && price.currency === config.currency) {
    const totalPrice =
      partsAmount && partsCurrency ? new Money(partsAmount + price.amount, partsCurrency) : price;
    const formattedPrice = formatMoney(intl, totalPrice, 0);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    priceUnit,
    isSchedulingListing,
    showContactUser,
    onContactUser,
  } = props;

  const price = listing.attributes.price;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { publicData } = listing.attributes;
  const partsAmount = publicData && publicData.partsAmount;
  const partsCurrency = publicData && publicData.partsCurrency;
  const { formattedPrice, priceTitle } = priceData(price, partsAmount, partsCurrency, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const category =
    listing &&
    listing.attributes &&
    listing.attributes.publicData &&
    listing.attributes.publicData.category;
  const address =
    listing &&
    listing.attributes &&
    listing.attributes.publicData &&
    listing.attributes.publicData.location &&
    listing.attributes.publicData.location.address
      .replace(', United States', '')
      ?.replace(', USA', '');
  const phoneNumber = listing?.author?.attributes?.profile?.publicData?.phone_number;
  const callLink = 'tel:' + phoneNumber;
  const isDailyListingType = config.custom.DAILY_LISTING_TYPES.includes(category);
  const isHourlyListingType = config.custom.HOURLY_LISTING_TYPES.includes(category);
  const isUnitListingType = !isDailyListingType && !isHourlyListingType;

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            {/* <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} /> */}
            <br></br>
            <br></br>
            {address}
            <br></br>
            <a className={css.text} href={callLink}>
              {formatPhoneNumber(phoneNumber)}
            </a>
          </div>
        </div>

        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          <p className={css.booking}>{address}</p>
          <a className={css.text} href={callLink}>
            {formatPhoneNumber(phoneNumber)}
          </a>
          {subTitleText && (isHourlyListingType || isDailyListingType) ? (
            <div className={css.booking}>{subTitleText}</div>
          ) : null}
        </div>

        {showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            listing={listing}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            isHourly={isHourlyListingType}
            isDaily={isDailyListingType}
            isUnit={isUnitListingType}
            onContactUser={onContactUser}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {/* <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
            {
              isSchedulingListing ? 
              <h4 className={css.perMonth}>per month</h4> : <h4 className={css.perMonth}>incl. parts & labor</h4>
            }
          </div>
        </div> */}
        {/* {showContactUser ? (
          <span className={css.contactWrapper}>
            <span className={css.separator}>•</span>
            <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
              <FormattedMessage id="ListingPage.contactUser" />
            </InlineTextButton>
          </span>
        ) : null} */}
        {showBookingTimeForm ? (
          <div className={css.buttonContainer}>
            <Button rootClassName={css.bookButton} onClick={onContactUser}>
              <FormattedMessage id="ListingPage.contactUser" />
            </Button>
            <Button
              rootClassName={css.bookButton}
              onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
            >
              <FormattedMessage id="BookingPanel.bookNow" />
              <br />
              {/* <FormattedMessage
                id={
                  isUnitListingType
                    ? 'BookingPanel.ctaBuyButtonMessage'
                    : 'BookingPanel.ctaButtonMessage'
                }
              /> */}
            </Button>
          </div>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
