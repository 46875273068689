import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';

import css from './AutomktRewards.module.css';
import captialAccessImg from './capitalAccess.png';
import cryptoRewardsImg from './cryptoReward.png';
import maintenanceImg from './maintenance.png';
import verticalLine from './verticalLine.png';
import commingSoon from './commingSoon.jpeg';
import { AiFillStar } from 'react-icons/ai';
const heading = () => {
  return (
    <StaticPage
      title="Web3 Rewards"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Web3 Rewards',
        description: 'Web3 Rewards',
        name: 'Web3 Rewards',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.automktTitle}>
            <AiFillStar className={css.star} />
            AM Rewards
          </h1>
          <div className={css.contentWrapper}>
            <p className={css.paragraph}>
              Helping drivers & fleets gain pricing power, while also sending customers to the most
              deserving dealers, shops & brokers. The <b>AutoMarketplace Rewards Program</b> is
              meant to provide discounts to those who use our directory and help share pricing
              insight.
            </p>
            <p className={css.pHeading}>
              <b>How does it work?</b>
            </p>
            <h2 className={css.subHeading}>✅ Step 1. Sign-Up</h2>
            <p className={css.paragraph}>
              Simple sign-up process: e-mail and name.{' '}
              <span className={css.italic}>
                Money is not exchanged on our site; we do not require any credit card or bank
                account details to interact on AutoMarketplace.com.
              </span>
            </p>
            <h2 className={css.subHeading}>📅 Step 2. Message & Book</h2>
            <p className={css.paragraph}>
              Use our messaging and booking tools to communicate with dealers, shops and brokers.
              You can start a conversation on AutoMarketplace.com and then call a dealer, shop or
              broker as well. However, the booking appointment request must be made and confirmed
              through the website to earn rewards. We provide vendor phone numbers for all of our
              listings.
            </p>
            <h2 className={css.subHeading}>📧 Step 3. Send Us Your Invoice</h2>
            <p className={css.paragraph}>
              Once you visit dealer, shop or broker and transact, email or message us your invoice
              at <a href="mailto:info@automarketplace.com">info@automarketplace.com</a>. You can
              hide or black out any personal information, we’re just focused on an invoice’s
              breakdown (parts, labor rate, taxes, etc).
            </p>
            <h2 className={css.subHeading}>
              {' '}
              <AiFillStar className={css.starSmall} />
              Step 4. Earn Rewards, Get Discounts
            </h2>
            <p className={css.paragraph}>
              After you send us your first validated invoice, we’ll create a coupon that can be used
              at any shop listed on AutoMarketplace.com. We’ll send you this coupon by email. If you
              send us more validated invoices, the coupons and benefits increase. A validated
              invoice simply means we confirm the invoice is real and relates to a booking made on
              AutoMarketplace.com.
            </p>
            {/* <div className={css.contentMain}>
              <p className={css.automktContent}>
                <FormattedMessage id="AutomktRewardsPage.paragraph" />
              </p>
              <div className={css.captialAccessContainer}>
                <div className={css.dottedLine}>
                  <img className={css.capitalAccess} src={captialAccessImg} alt="Capital access image" />
                  <img src={verticalLine} alt="Dotted line" />
                </div>
                <div className={css.text}>
                  <h3 className={css.heading}><FormattedMessage id="AutomktRewardsPage.accessToCapitalHeading" /></h3>
                  <p className={css.paragraph}>
                    <FormattedMessage id="AutomktRewardsPage.accessToCapitalParagraph" />
                  </p>
                </div>
              </div>
              <div className={css.captialAccessContainer}>
                <div className={css.dottedLine}>
                  <img className={css.maintenance} src={maintenanceImg} alt="Car Maintenance image" />
                  <img src={verticalLine} alt="Dotted line" />
                </div>
                <div className={css.text}>
                  <h3 className={css.heading}><FormattedMessage id="AutomktRewardsPage.discountedAutoPartsAndServiceHeading" /></h3>
                  <p className={css.paragraph}>
                    <FormattedMessage id="AutomktRewardsPage.discountedAutoPartsAndServiceParagraph" />
                  </p>
                </div>
              </div>
              <div className={css.captialAccessContainer}>
                <img className={css.crypto} src={cryptoRewardsImg} alt="Crypto Rewards image" />
                <div className={css.text}>
                  <h3 className={css.heading}><FormattedMessage id="AutomktRewardsPage.cryptoRewardsHeading" /></h3>
                  <p className={css.paragraph}>
                    <FormattedMessage id="AutomktRewardsPage.cryptoRewardsParagraph" />
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div >
                  <img className={css.comingSoonImage} src={commingSoon} alt="Capital access image" />
                </div> */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default heading;
