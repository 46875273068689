import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  SectionAboutUs,
  SectionConfirmedPricing,
  SectionRespectingYourTime,
  SectionWarrantyOnAllWork,
  SectionFinancingAutoRepair,
  SectionPrepaidMaintenancePackages,
  SectionWeb3AndUserOwnership,
  Footer,
  NamedLink,
} from '../../components';

import css from './AboutPage.module.css';

const AboutPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Automarketplace',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentMain}>
          <ul>
            <li>
              <div className={css.section}>
                <SectionAboutUs />
              </div>
            </li>
            {/* <li >
              <div className={css.section}>
                <SectionConfirmedPricing />
              </div>
            </li> */}
            {/* <li >
              <div className={css.section}>
                <SectionWarrantyOnAllWork />
              </div>
            </li> */}
            {/* <li >
              <div className={css.section}>
                <SectionRespectingYourTime />
              </div>
            </li> */}
            {/* <li >
              <div className={css.section}>
                <SectionFinancingAutoRepair />
              </div>
            </li> */}
            {/* <li >
              <div className={css.section}>
                <SectionPrepaidMaintenancePackages />
              </div>
            </li> */}
            {/* <li >
              <div className={css.section}>
                <SectionWeb3AndUserOwnership />
              </div>
            </li> */}
          </ul>
          {/* <div className={css.buttonContainer}>
            <NamedLink name="AutomktRewards" className={css.buttonLink} >
              <button className={css.userOwnershipBtn}><FormattedMessage id="AboutPage.userOwnershipButton" />
                <svg id="Layer" enable-background="new 0 0 64 64" className={css.rightArrow} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" /></svg>
              </button>
            </NamedLink>
          </div> */}
            
           
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn >
    </StaticPage >
  );
};

export default AboutPage;
