import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { UserCard, Modal } from '../../components';
import { EnquiryForm } from '../../forms';
import vendorIcon from './icons/vendorIcon.png';

import css from './ListingPage.module.css';

const SectionHostMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    onManageDisableScrolling,
    totalRating,
  } = props;

  if (!listing.author) {
    return null;
  }

  return (
    <div id="host" className={css.sectionHost}>
      {/* <div className={css.vendorIconWrapper}>
        <img src={vendorIcon} alt="Vendor" className={css.vendorIcon} />
        <h2 className={css.yourHostHeading}>
          <FormattedMessage id="ListingPage.yourHostHeading" />
        </h2>
      </div> */}
      {/* <UserCard currentListing={listing} authorDisplayName={authorDisplayName} user={listing.author} currentUser={currentUser} onContactUser={onContactUser} totalRating={totalRating}/> */}
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionHostMaybe;
