import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import coinOnHill from './images/coinOnHill.png';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';


import css from './EarningAUTOMKTToken.module.css';


const EarningAUTOMKTTokenPage = () => {

  // prettier-ignore
  return (
    <StaticPage
      title="EarningAUTOMKTTokenPage"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'EarningAUTOMKTTokenPage',
        description: 'Earning AUTOMKT tokens',
        name: 'EarningAUTOMKTTokenPage',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.blueLine}></div>
          <h1 className={css.pageTitle}><FormattedMessage id="EarningAUTOMKTTokensPage.title" /></h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p className={css.paragraph}>
                <FormattedMessage id="EarningAUTOMKTTokensPage.mainParagraph" />
              </p>
              <h2 className={css.transactionsHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.transactionsHeading" /></h2>
              <div className={css.totalTransactions_container}>
                <h2 className={css.totalTransactionsHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.totalTransactionsSubHeading" /></h2>
                <h2 className={css.additionalTransactionsHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.additionalTokensSubHeading" /></h2>
              </div>
              <div className={css.container_lists}>
                <ul className={css.containerRight_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.10" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.50" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.100" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.500" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.2000+" /></li>
                </ul>
                <ul className={css.containerLeft_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.10" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.50" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.100" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.500" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.5000" /></li>
                </ul>
              </div>
              <p className={css.transactionsContent}><FormattedMessage id="EarningAUTOMKTTokensPage.transactionsLineOne" /></p>
              <p className={css.transactionsContent}><FormattedMessage id="EarningAUTOMKTTokensPage.transactionsLineTwo" /></p>
              <h2 className={css.transactedHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.valueTransactedHeading" /></h2>
              <div className={css.totalTransacted_container}>
                <h2 className={css.totalTransacedValueHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.valueSubHeading" /></h2>
                <h2 className={css.additionalTransactedHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.additionalTokensSubHeading" /></h2>
              </div>
              <div className={css.container_lists}>
                <ul className={css.containerRight_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.$500" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.$1,000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.$5,000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.$10,000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.$50,000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.$100,000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.$1,000,000+" /></li>
                </ul>
                <ul className={css.containerLeft_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.10" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.20" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.100" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.500" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.5000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.10000" /></li>
                </ul>
              </div>
              <p className={css.transactionsContent}><FormattedMessage id="EarningAUTOMKTTokensPage.valueTransactedLine" /></p>
              <h2 className={css.transactedHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.vendorRatingHeading" /></h2>
              <div className={css.vendorRating_container}>
                <h2 className={css.totalTransactionsHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.ratingSubHeading" /></h2>
                <h2 className={css.additionalVendorHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.additionalTokensSubHeading" /></h2>
              </div>
              <div className={css.container_lists}>
                <ul className={css.containerRight_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.4.40+" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.4.50+" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.4.60+" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.4.70+" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.4.80+" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.4.90+" /></li>
                </ul>
                <ul className={css.containerLeft_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.2000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.3000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.4000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.5000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.10000" /></li>
                </ul>
              </div>
              <p className={css.transactionsContent}><FormattedMessage id="EarningAUTOMKTTokensPage.vendorRatingLine" /></p>
              <h2 className={css.transactedHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.signUpNewVendorHeading" /></h2>
              <div className={css.newVendor_container}>
                <h2 className={css.totalTransactionsHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.vendorUsersSubHeading" /></h2>
                <h2 className={css.additionalNewVendorHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.additionalTokensSubHeading" /></h2>
              </div>
              <div className={css.container_lists}>
                <ul className={css.containerRight_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.5" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.10" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.100" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.250" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.500" /></li>
                </ul>
                <ul className={css.containerLeft_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.100" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.500" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.2000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.5000" /></li>
                </ul>
              </div>
              <p className={css.transactionsContent}><FormattedMessage id="EarningAUTOMKTTokensPage.signUpNewVendorLine" /></p>
              <h2 className={css.transactedHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.youTubeContentHeading" /></h2>
              <div className={css.youtubeContent_container}>
                <h2 className={css.totalTransactionsHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.viewsSubHeading" /></h2>
                <h2 className={css.additionalYoutubeHeading}><FormattedMessage id="EarningAUTOMKTTokensPage.additionalTokensSubHeading" /></h2>
              </div>
              <div className={css.container_lists}>
                <ul className={css.containerRight_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.5000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.10000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.20000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.50000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.100000" /></li>
                </ul>
                <ul className={css.containerLeft_list}>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.10" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.50" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.100" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.500" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.1000" /></li>
                  <li><FormattedMessage id="EarningAUTOMKTTokensPage.5000" /></li>
                </ul>
              </div>
              <p className={css.transactionsContent}><FormattedMessage id="EarningAUTOMKTTokensPage.youTubeContentLine" /></p>
              <p className={css.paragraph}>
                <span className={css.note}> <FormattedMessage id="EarningAUTOMKTTokensPage.youTubeContentNote" /></span><FormattedMessage id="EarningAUTOMKTTokensPage.youTubeContentNoteParagraph" />
              </p>
              <div className={css.imagePlaceHolder}>
                <div>
                  <div className={css.blueLine}></div>
                  <h1 className={css.pageTitle}>  <FormattedMessage id="EarningAUTOMKTTokensPage.automktTokensAndRegulationHeading" /></h1>
                  <p className={css.paragraph}>
                    <FormattedMessage id="EarningAUTOMKTTokensPage.automktTokensAndRegulationParagraphOne" />
                  </p>
                  <p className={css.paragraph}>
                    <FormattedMessage id="EarningAUTOMKTTokensPage.automktTokensAndRegulationParagraphTwo" />
                  </p>
                  <p className={css.paragraph}>
                    <FormattedMessage id="EarningAUTOMKTTokensPage.automktTokensAndRegulationParagraphThree" />
                  </p>
                </div>
                <img className={css.image} src={coinOnHill} alt="Coin on Hill" />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default EarningAUTOMKTTokenPage;
