import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionTokenonmics.module.css';
import pieChart from './images/pieChart.png';
import { string } from 'prop-types';

const SectionTokenonmics = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionTokenonmics.title" />
      </h1>
      <div className={css.contentWrapper}>
        <div>
          <p className={css.paragraph}>
            {/* <FormattedMessage id="SectionTokenonmics.paragraph1.1" /> */}
            <b>20 million reward tokens will be created</b>. The founders & management will be
            allocated 20% of all tokens, but have voting control of strategic decisions to ensure
            AutoMarketplace is efficiently run. To correctly incentivize continued usage of the
            marketplace, Tokens will have a 2% annual inflation rate after all 20 million tokens are
            distributed.{' '}
            <b>
              Tokens will be distributed to users after 20 million tokens are earned and regulatory
              approvals received (see below)
            </b>
            .
          </p>
        </div>
        <img className={css.image} src={pieChart} alt="Pie Chart of Tokenonmics" />
      </div>
    </div>
  );
};

SectionTokenonmics.defaultProps = { rootClassName: null, className: null };

SectionTokenonmics.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTokenonmics;
