import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionFinancingAutoRepair.module.css';
import bnpl from './images/bnpl.png';
import { string } from 'prop-types';
import { NamedLink } from '../../components';

const SectionFinancingAutoRepair = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  const bnplLink = <NamedLink name="BNPLPage">
                    <FormattedMessage id="SectionFinancingAutoRepair.paragraph1.5" />
                  </NamedLink>
  return (
    <div className={classes}>
      {/* <div className={css.blueLine}></div> */}
      <h1 className={css.sectionTitle}>
        <FormattedMessage id="SectionFinancingAutoRepair.title" />
      </h1>
      <div className={css.contentWrapper}>
        <div>
          <p className={css.paragraph}>
            <FormattedMessage id="SectionFinancingAutoRepair.paragraph1.1" />
            <a
              href="https://newsroom.aaa.com/2017/04/one-three-u-s-drivers-cannot-pay-unexpected-car-repair-bill/ "
              target="_blank"
            >
              <FormattedMessage id="SectionFinancingAutoRepair.paragraph1.2" />
            </a>
            , &nbsp;
            <FormattedMessage id="SectionFinancingAutoRepair.paragraph1.3" />
            <br />
            <FormattedMessage id="SectionFinancingAutoRepair.paragraph1.3.2" />
            <NamedLink name="BNPLPage">
              <FormattedMessage id="SectionFinancingAutoRepair.paragraph1.4" />
            </NamedLink>
            <FormattedMessage id="SectionFinancingAutoRepair.paragraph1.5" />
          </p>
        </div>
        {/* <img className={css.image} src={bnpl} alt="Clock" /> */}
      </div>
    </div>
  );
};

SectionFinancingAutoRepair.defaultProps = { rootClassName: null, className: null };

SectionFinancingAutoRepair.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFinancingAutoRepair;
