import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import blueIcon from './icons/blueIcon.png';
import applicableIcon from './icons/applicableIcon.png';
import warrantyIcon from './icons/warrantyIcon.png';
import { richText, convertYoutubeLinksToIframes } from '../../util/richText';
import StarRatings from 'react-star-ratings';
let parse = null;
typeof window !== 'undefined'
  ? import('html-react-parser').then(h => {
      parse = h.default;
    })
  : null;

import css from './ListingPage.module.css';
import Star from '../../components/Star/Star';

const youtubeIframe = ytl => {
  const removeHtmlTags = ytl?.replace(/(<([^>]+)>)/gi, '');
  const videoKey = removeHtmlTags?.replace(/['"]/g, '');
  const src = '//www.youtube.com/embed/' + videoKey;
  console.log('src', src);
  return (
    <iframe
      className={css.youtubeIframe}
      src={src}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  );
};
const SectionDescriptionMaybe = props => {
  const { description, currentListing } = props;
  const model =
    currentListing &&
    currentListing.attributes &&
    currentListing.attributes.publicData &&
    currentListing.attributes.publicData.applicableModels;
  const warranty =
    currentListing &&
    currentListing.attributes &&
    currentListing.attributes.publicData &&
    currentListing.attributes.publicData.warranty;
  const parsedDescriptionLinks = convertYoutubeLinksToIframes(description);
  return description ? (
    <div className={css.sectionDescription}>
      <div className={css.iconIWrapper}>
        <Star />
        {/* <img src={blueIcon} alt="Blue Icon i" className={css.iconI} /> */}
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.descriptionTitle" />
        </h2>
      </div>
      <div className={css.imageMaxWidth}>
        {parse !== null ? parse(parsedDescriptionLinks[0]) : null}
      </div>
      <p>{parsedDescriptionLinks[1].map(l => youtubeIframe(l))}</p>
      {/* 
      {model ?
        <div>
          <br/>
          <div className={css.iconIWrapper}>
            <img src={applicableIcon} alt="Applicable Icon" className={css.iconI} />
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.modelsDescription" />
            </h2>
          </div>
          <p>{model}</p>
        </div> : null} */}
      {warranty ? (
        <div>
          <br />
          <div className={css.iconIWrapper}>
            <img src={warrantyIcon} alt="Warranty Icon" className={css.iconI} />
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.warrantyDescription" />
            </h2>
          </div>
          <p>{warranty}</p>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
